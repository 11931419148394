.sidebar {
  background-color: black;
  width: 3.875rem;
  top: 0;
  left: 0;
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  height: 100vh;
  overflow-y: auto;
  &__multiselect {
    height: 2.75rem;
    background-color: transparent;
    border: 1px solid white;
    width: 90%;
    margin-bottom: 1.25rem;
    color: white;
    font-family: var(--primary-font);
  }
  .multiselect-single-label {
    width: 100%;
  }
  .multiselect-option {
    height: min-content;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    p {
      margin: 0;
    }
    p:last-child {
      opacity: 0.5;
    }
    &:hover {
      background-color: var(--blue-color);
    }
  }
  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
  &__block-date {
    background-color: var(--blue-color);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__block-date--bordo {
    background-color: #801c62;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__text {
    display: block;
    color: var(--secondary-color);
    font-family: var(--primary-font);
    font-size: 0.875rem;
    line-height: 1.2;
    letter-spacing: 1.4;
    margin: 2.125rem 0 0.45rem 0;
  }
  &__date {
    display: block;
    color: var(--secondary-color);
    // margin: 0.625rem 0 1.125rem 0;
    text-align: center;
    margin: 0 0 2.125rem 0;
    font-family: var(--primary-font);
    font-size: 1.5rem;
    line-height: 1.2;
    letter-spacing: 1.2;
  }
  &__compare {
    display: flex;
    background-color: var(--blue-color);
    opacity: 0.5;
    color: var(--secondary-color);
    width: 10rem;
    min-height: 35px;
    border: none;
    font-family: var(--primary-font);
    font-size: 0.875rem;
    cursor: auto;
    margin: 1.875rem 0;
    justify-content: center;
    align-items: center;
  }
  &__compare--bordo {
    display: flex;
    background-color: #801c62;
    opacity: 0.5;
    color: var(--secondary-color);
    width: 10rem;
    min-height: 35px;
    border: none;
    font-family: var(--primary-font);
    font-size: 0.875rem;
    cursor: auto;
    margin: 1.875rem 0;
    justify-content: center;
    align-items: center;
  }
  &__files {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 10px 50px;
    font-size: 0.875rem;
    font-family: var(--primary-font);
    border: none;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    margin-bottom: 50px;
  }
  &__files:hover {
    background-color: var(--blue-color);
    transition: all 0.1s ease-in-out;
  }
  &__compare--allowed {
    opacity: 1;
    cursor: pointer;
  }
  &__mobile--main {
    width: 2.5rem;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: black;
    z-index: 99;
  }
  &__helper {
    position: relative;
    top: 40%;
  }
  &__mobile--element,
  &__mobile--element-bordo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.75rem;
    width: 100%;
    border-bottom: 1px solid var(--primary-color);
  }
  &__mobile--element:hover .opacity {
    opacity: 100%;
  }
  &__mobile--element:first-child,
  &__mobile--element-bordo:first-child {
    border-top: 1px solid var(--primary-color);
  }
  &__mobile--element:hover {
    background-color: var(--blue-color);
    cursor: pointer;
  }
  &__mobile--element-bordo:hover {
    background-color: #801c62;
    cursor: pointer;
  }
  &__mobile {
    background-color: black;
    width: 19.375rem;
    left: -19.375rem;
    top: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    overflow-x: hidden;
    height: 100vh;
    // padding-top: 1.375rem;
    z-index: 100;
    .multiselect-single-label {
      width: 100%;
    }

    .multiselect-option {
      height: min-content;
      background-color: black;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      cursor: pointer;

      p {
        margin: 0;
      }

      p:last-child {
        opacity: 0.5;
      }

      &:hover {
        background-color: var(--blue-color);
      }
    }
  }
  &__transform--left {
    transition-timing-function: ease-in-out;
    transition: transform 0.5s;
    transform: translateX(-19.375rem);
  }
  &__transform--right {
    transition-timing-function: ease-in-out;
    transition: transform 0.5s;
    transform: translateX(19.375rem);
  }
  &__block {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__consultant {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__picture {
    height: 3.75rem;
    width: 3.75rem;
    border-radius: 50%;
    background-color: var(--secondary-color);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__picture img {
    border-radius: 50%;
    height: min-content;
    width: 100px;
    height: 67px;
  }
  &__picture--header {
    color: var(--secondary-color);
    font-family: var(--primary-font);
    font-size: 1.125rem;
    margin: 0;
    text-align: center;
  }
  &__picture--description {
    color: var(--primary-color);
    font-family: var(--primary-font);
    margin: 0 0 0.45rem 0;
    font-size: 0.875rem;
    text-align: center;
    line-height: 1.6;
    width: 10.125rem;
  }
  &__button {
    background-color: var(--blue-color);
    color: var(--secondary-color);
    width: 6.875rem;
    height: 35px;
    border: none;
    font-family: var(--primary-font);
    font-size: 0.875rem;
    letter-spacing: 1.2;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__button:hover {
    background-color: black;
    cursor: pointer;
    width: auto;
    font-size: 1.125rem;
  }
  &__button--bordo {
    background-color: #801c62;
    color: var(--secondary-color);
    width: 6.875rem;
    height: 35px;
    border: none;
    font-family: var(--primary-font);
    font-size: 0.875rem;
    letter-spacing: 1.2;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__button--bordo:hover {
    background-color: black;
    cursor: pointer;
    width: auto;
    font-size: 1.125rem;
  }
  &__header {
    display: block;
    margin: 1.675rem 0 1.25rem 0;
    color: var(--secondary-color);
    font-family: var(--secondary-font);
    font-size: 1.125rem;
    letter-spacing: 0.8;
  }
  &__block {
    display: flex;
    width: 100%;
  }
  &__prev {
    top: 50%;
    left: -1.25rem;
  }
  &__next {
    top: 50%;
    right: -1.25rem;
  }
}
.opacity {
  opacity: 25%;
}
.blur {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
  backdrop-filter: blur(0.25rem);
}
.side {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 50px;
  }
  &__text {
    color: var(--secondary-color);
    font-family: var(--secondary-font);
    font-size: 1.125rem;
    letter-spacing: 0.8;
    margin: 2.375rem 0 0;
    text-align: center;
  }
  &__container--mobile {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    margin-top: 2rem;
    margin-bottom: 2.5rem;
    position: relative;
  }
  &__block--mobile {
    display: flex;
    width: 10.125rem;
    gap: 3.125rem;
  }
  &__detail--container {
    width: 100%;
    margin-bottom: 100px;
  }
  &__detail--mobile {
    width: 100%;
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--primary-color);
  }
  &__detail--mobile:first-child {
    border-top: 1px solid var(--primary-color);
  }
  &__detail--text {
    font-family: var(--primary-font);
    font-size: 0.875rem;
    color: var(--secondary-color);
    margin-left: 1.25rem;
  }
  &__detail--icon {
    width: 1.5rem;
    height: 1.5rem;
    opacity: 25%;
    margin-right: 1.125rem;
  }
  &__detail--image {
    width: 1.5rem;
    height: 1.5rem;
  }
  &__detail--mobile:hover &__detail--icon {
    opacity: 100%;
  }
  &__detail--mobile:hover {
    background-color: var(--blue-color);
    cursor: pointer;
  }
  &__offer--link {
    height: 3.75rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.625rem;
    border-bottom: 1px solid var(--primary-color);
  }
  &__offer--link:first-child {
    border-top: 1px solid var(--primary-color);
    margin-top: 15.625rem;
  }
  &__offer--link:last-child {
    margin-bottom: 3.125rem;
  }
  &__offer--link:hover {
    background-color: var(--blue-color);
    cursor: pointer;
  }
  &__offer--link:hover &__offer--icon {
    opacity: 100%;
  }
  &__offer--text {
    margin: 0 0 0 1.25rem;
    font-family: var(--primary-font);
    font-size: 0.875rem;
    color: var(--secondary-color);
  }
  &__offer--icon {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 1.25rem 0 0;
    opacity: 25%;
  }
  &__offer--image {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.offer {
  &__button {
    width: 100%;
    height: 3.75rem;
    display: flex;
    background-color: black;
    padding: 0 0.875rem 0 0.875rem;
    color: var(--secondary-color);
    gap: 0.75rem;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-bottom: 1px solid var(--primary-color);
  }
  &__button--bordo {
    width: 100%;
    height: 3.75rem;
    display: flex;
    background-color: black;
    padding: 0 0.875rem 0 0.875rem;
    color: var(--secondary-color);
    gap: 0.75rem;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-bottom: 1px solid var(--primary-color);
  }
  &__button:first-child{
    border-top: 1px solid var(--primary-color);
  }
  &__button--bordo:first-child {
    border-top: 1px solid var(--primary-color);
  }
  &__button:hover {
    background-color: var(--blue-color);
    cursor: pointer;
  }
  &__button--bordo:hover {
    background-color: #801c62;
    cursor: pointer;
  }
  &__icon {
    width: 0.875rem;
    height: 1.25rem;
  }
  &__icon--image {
    width: 0.875rem;
    height: 1.25rem;
  }
  &__button--description {
    font-family: var(--primary-font);
    margin: 0.1875rem;
    font-size: 0.875rem;
    letter-spacing: 0.7;
    width: 100%;
  }
  &__button--check {
    display: block;
    position: relative;
    width: 21px;
    height: 1rem;
    border: 1px solid white;
    border-radius: 0.1875rem;
  }
  &__check--icon {
    position: absolute;
    top: 1px;
    left: -1px;
  }
  &__block {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
  }
  &__container--block {
    width: 100%;
  }
}
.files {
  &__button {
    width: calc(100% - 2rem);
    height: 4.5rem;
    display: flex;
    background-color: black;
    padding: 0 1rem;
    justify-content: space-between;
    color: var(--secondary-color);
    align-items: center;
    border-bottom: 1px solid var(--primary-color);
    border-top: 1px solid var(--primary-color);
    p {
      font-family: var(--primary-font);
      font-size: 0.875rem;
    }
    img {
      height: 20px;
      width: 20px;
    }
  }
  &__button:hover {
    background-color: var(--blue-color);
    cursor: pointer;
  }
  &__button:last-of-type {
    margin-bottom: 50px;
    border-top: none;
  }
}
.is-hidden-icon {
  display: none;
}
.button__is-clicked {
  background-color: var(--blue-color);
}
.button__is-clicked--bordo {
  background-color: #801c62;
}
.box__is-clicked {
  background-color: var(--secondary-color);
}
.blur-isOpen {
  width: 100%;
}
@media screen and (min-width: 980px) {
  .sidebar {
    width: 13.125rem;
    &__compare {
      margin: 3.125rem 0 1rem;
    }
    &__block {
      flex-direction: row;
    }
    &__consultant {
      align-items: flex-start;
    }
    &__picture--header {
      text-align: left;
    }
    &__picture--description {
      text-align: left;
    }
  }
  .side {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;
      margin-bottom: 50px;
    }
  }
}
@media screen and (min-width: 1450px) {
  .sidebar {
    width: 16.375rem;
  }
  .offer {
    &__button--check {
      width: 1.25rem;
    }
  }
}
