.yourfiles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // margin-top: 3.125rem;
  opacity: 0;
  &__container--mobile {
    margin-top: 2rem;
    width: 100%;
  }
  &__block--mobile {
    height: 135px;
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
  }
  &__download--icon {
    position: absolute;
    top: 1.25rem;
    right: 2.25rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }
  &__block--mobile:nth-child(odd) {
    background-color: #f0f1f0;
  }
  &__block--header {
    font-family: var(--primary-font);
    font-size: 1.125rem;
    color: black;
    max-width: 15.625rem;
    margin: 0 37px;
  }
  &__download--image {
    width: 1.5rem;
    height: 1.5rem;
  }
  &__block--date {
    font-family: var(--primary-font);
    font-size: 1.125rem;
    color: var(--primary-color);
    margin: 0.3125rem 37px 0.75rem;
  }
  &__block--text {
    font-family: var(--primary-font);
    font-size: 0.875rem;
    color: var(--primary-color);
    margin: 0 37px;
    text-wrap: balance;
  }
  &__text {
    font-family: var(--primary-font);
    font-size: 0.75rem;
    color: var(--primary-color);
    margin: 1.875rem 0 0.875rem 0;
    text-align: center;
  }
  &__animation{
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }
}
.yourfiles .heading__icon {
  display: none;
}
.underline {
  text-decoration: underline;
  cursor: pointer;
  margin: 1.1875rem 0 1.25rem 0;
}
.no-display{
  display: none;
}
@media screen and (min-width: 610px) {
  .yourfiles {
    &__download--icon {
      top: 40%;
    }
  }
}
@media screen and (min-width: 980px) {
  .yourfiles {
    // margin-top: 5rem;
    padding: 0 6.25rem;
    &__table {
      width: 100%;
      margin: 2.25rem 0 2.5rem 0;
    }
    &__row {
      height: 7rem;
      padding: 0 4.375rem;
    }
    &__row:first-child {
      height: 5rem;
    }
    &__row:nth-child(even) {
      background-color: #f0f1f0;
    }
    &__data:first-child {
      max-width: 31rem;
    }
    &__data:last-child {
      padding-right: 4.375rem;
    }
    &__data {
      padding-left: 4.375rem;
      text-wrap: wrap;
      font-family: var(--primary-font);
      font-size: 1.125rem;
    }
    &__data:nth-child(3) {
      font-size: 0.875rem;
      color: var(--primary-color);
      text-wrap: balance;
      max-width: 20rem;
    }
    &__header {
      text-align: left;
      padding-left: 4.375rem;
      font-family: var(--primary-font);
      font-size: 1.125rem;
      font-weight: 500;
    }
    &__header:first-child {
      padding-left: 4.375rem;
    }
    &__icon {
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
    }
    &__icon--image {
      width: 1.5rem;
      height: 1.5rem;
    }
    &__text {
      font-size: 0.875rem;
      margin: 0 0 0.875rem 0;
    }
  }
  .underline {
    margin: 1.1875rem 0 0;
  }
}
@media screen and (min-width: 1450px) {
  .yourfiles {
    &__row {
      padding: 0 7.75rem;
    }
    &__data:first-child {
      padding-left: 7.75rem;
    }
    &__data:last-child {
      padding-right: 7.75rem;
    }
    &__header:first-child {
      padding-left: 7.75rem;
    }
  }
}
