.main {
  position: relative;
  padding-left: 2.5rem;
}
.main__preview {
  background-color: rgb(24, 172, 44);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: var(--primary-font);
  padding: 25px 0px;
  font-size: 22px;
}
.main__text {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-family: var(--primary-font);
}
.loader__container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.adnotation__text {
  width: 100%;
  text-align: center;
  text-wrap: balance;
  font-family: var(--primary-font);
  color: var(--primary-color);
}
@media screen and (min-width: 980px) {
  .main {
    padding-left: 13.125rem;
  }
}
@media screen and (min-width: 1450px) {
  .main {
    padding-left: 16.375rem;
  }
}
@media print {
  .sidebar__wrapper, .footer, .yourfiles {
    display: none !important;
  }
  .slider__car--info {
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 0 6.25rem !important;
    height: 231px !important;
    margin: 1.25rem 0 0 0 !important;
  }
  .slider__car--text {
    font-size: 1.5rem !important;
  }
  .slider__car--block,
  .slider__car--block--bordo,
  .slider__car--block--blue {
    max-width: 18.125rem !important;
    max-height: 11.25rem !important;
    width: 18.125rem !important;
    height: 11.25rem !important;
    padding-left: 1.5rem !important;
    gap: 0 !important;
  }
  .slider__car--font {
    font-size: 1rem !important;
    margin: 0 !important;
  }
  .slider__car--description {
    font-size: 1.25rem !important;
    margin: 39px 0 0.375rem 0 !important;
  }
  .slider__car--price {
    font-size: 3.75rem !important;
    width: 18.75rem !important;
    margin: 0 !important;
  }
  .slider__wrapper {
    height: 37.5rem !important;
  }
  .slider__wrapper--gallery {
    height: 37.5rem !important;
  }
  .slider__main--image {
    width: 66.75rem !important;
    height: 37.5rem !important;
  }
  .heading {
    font-size: 3rem !important;
  }
  .configuration__list {
    margin: 1.25rem 0 4.375rem 0 !important;
    padding: 0 5rem !important;
    flex-wrap: nowrap !important;
    row-gap: 0 !important;
  }
  .element {
    &__info {
      margin: 2.25rem 0 0.25rem 0 !important;
      font-size: 0.875rem !important;
    }

    &__detail {
      font-size: 1.5rem !important;
      text-wrap: balance !important;
      text-align: center !important;
    }
  }
  .configuration__table {
    width: 60.125rem !important;
    margin-bottom: 4.375rem !important;
  }
  .table {
    &__data:first-child {
      width: 6.25rem !important;
    }

    &__data:last-child {
      width: 9.375rem !important;
    }
  }
  .sliderCarBlock__container {
    flex-direction: row !important;
  }
  .specials__list {
    flex-direction: row !important;
  }
}
