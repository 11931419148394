.chat {
  position: fixed;
  bottom: 20px;
  right: 40px;
  background-color: #801c62;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    &--block {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
      &-dealer {
        font-family: var(--primary-font);
        font-size: 18px;
        font-weight: 600;
        color: var(--secondary-color);
      }
    }
  }
  &__main {
    width: calc(100% - 20px);
    background-color: var(--secondary-color);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 20px;
    &--messages {
      max-height: 90%;
      height: 90%;
      display: flex;
      flex-direction: column;
      gap: 5px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      &-user {
        display: flex;
        flex-direction: column;
        align-items: end;
        gap: 5px;
        div:first-of-type {
          font-size: 9px;
          text-align: right;
          font-family: var(--primary-font);
          letter-spacing: 0.4px;
        }
        div:last-of-type {
          background-color: rgba(128, 28, 98, 0.2);
          font-size: 13px;
          font-family: var(--primary-font);
          border-radius: 10px;
          padding: 20px;
          max-width: 80%;
          word-wrap: break-word;
          overflow-wrap: break-word;
          white-space: pre-wrap;
        }
      }
    }
    &--input {
      height: calc(10% - 20px);
      border: 1px solid var(--primary-color);
      position: relative;
      border-radius: 10px;
      padding: 10px;
      margin-top: 10px;
      input {
        border: none;
        height: 100%;
        width: 90%;
        outline: none;
        padding-left: 20px;
      }
      img {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }
}
.chat-hidden {
  height: 20px;
  width: 20px;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  cursor: pointer;
  padding: 20px;
  transition: all 0.3s ease-in-out;
}
.chat-shown-small {
  height: 60vh;
  width: calc(30vw - 40px);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  transition: all 0.3s ease-in-out;
  .chat__main {
    height: 82%;
  }
}
.chat-shown-big {
  width: 40vw;
  height: 90vh;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  transition: all 0.3s ease-in-out;
  .chat__main {
    height: 88%;
  }
}
