.comparing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: grab;
  padding: 0;
  & .carousel__track{
    margin: 0;
  }
  & .carousel__slide{
    display: block;
  }
  &__table .carousel{
    width: 169px;
  }
  &__table .carousel__viewport{
    width: 169px;
  }
  & .carousel__viewport{
    width: 169px;
  }
  &__header {
    font-family: var(--secondary-font);
    font-size: 1.875rem;
    margin: 1.875rem 0;
    line-height: 1;
    text-align: center;
  }
  &__list--block-up{
    width: 100%;
    height: 100%;
  }
  &__list--container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 87px;
    gap: 0.375rem;
    margin-left: 7.375rem;
  }
  &__list--wrapper-up {
    height: 87px;
    width: 9.625rem;
    position: relative;
  }
  &__list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 0.625rem;
    height: 100%;
  }
  &__element {
    width: 9.625rem;
    height: 87px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__image {
    width: 9.625rem;
    height: 87px;
  }
  &__list--wrapper-down {
    height: 87px;
    width: 19.875rem;
  }
  &__model {
    font-family: var(--secondary-font);
    font-size: 1.125rem;
    font-weight: 600;
    text-wrap: balance;
    margin: 0.625rem 0 0;
    line-height: 1.4;
  }
  &__price {
    font-family: var(--secondary-font);
    font-size: 1.125rem;
    font-weight: 600;
    text-wrap: balance;
    color: #909090;
    margin: 0 0 0.25rem;
  }
  &__description {
    font-family: var(--primary-font);
    font-size: 0.625rem;
    color: #909090;
    margin: 0;
  }
  &__main {
    width: 100%;
    display: flex;
    margin-bottom: 39px;
  }
  &__side {
    max-width: 7.375rem;
    margin-left: 0;
    z-index: 3;
    position: relative;
  }
  &__side--list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  &__side--element:nth-child(odd) {
    background-color: #f0f1f0;
  }
  &__side--element:nth-child(even) {
    background-color: var(--secondary-color);
  }
  &__side--element {
    height: 35px;
    padding-top: 25px;
    padding-left: 1.25rem;
    font-family: var(--primary-font);
    font-size: 0.625rem;
    width: 6.125rem;
  }
  &__side--header {
    font-family: var(--primary-font);
    font-size: 1.125rem;
    margin: 1.25rem 0;
    height: 1.25rem;
    padding-left: 1.25rem;
    text-wrap: nowrap;
  }
  &__main--right {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
  }
  &__main-right .carousel{
    width: 23.25rem;
  }
  &__side--table {
    display: flex;
    flex-direction: column;
    max-width: 17rem;
  }
  &__side--table-element {
    height: 3.75rem;
    display: flex;
    align-items: center;
    font-family: var(--primary-font);
    font-size: 0.625rem;
    padding-left: 1.25rem;
    text-wrap: balance;
  }
  &__side--table-element:nth-child(odd) {
    background-color: #f0f1f0;
  }
  &__side--table-element:nth-child(even) {
    background-color: var(--secondary-color);
  }
  &__table {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 0.625rem;
    background: linear-gradient(#f0f1f0 50%, white 50%);
    background-size: 100% 7.5rem;
  }
  &__row {
    display: flex;
    flex-direction: column;
    width: 9.625rem;
  }
  &__data {
    height: 3.75rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--primary-font);
    font-size: 0.625rem;
  }
  &__data:nth-child(odd) {
    background-color: #f0f1f0;
  }
  &__icon--next{
    position: absolute;
    top: 50%;
    right: -13px;
  }
  &__icon--prev{
    position: absolute;
    top: 50%;
    left: -13px;
  }
}
.no-display {
  display: none;
}
.is-hidden {
  display: none;
}
@media screen and (min-width: 980px) {
  .is-hidden {
    display: block;
  }
  .comparing {
    cursor: auto;
    &__header {
      font-size: 3.75rem;
      margin: 3.125rem 0;
    }
    &__list--container {
      height: 12rem;
      margin-left: 0;
      // margin-left: 357px;
    }
    &__list--container:nth-child(odd) {
      height: 120px;
      position: sticky;
      top: 0;
      z-index: 5;
      background-color: white;
      padding-bottom: 20px;
      transition: all 0.5s ease-in-out;
    }
    &__list--gradient{
     background: linear-gradient(to bottom, white 85%, #0000001A 90%, #00000000 100%);
      transition: all 0.5s ease-in-out;
    }
    &__list--wrapper-down {
      height: 87px;
      width: 23.25rem;
      margin-left: 357px;
    }
    &__list--wrapper-up {
      height: 12rem;
      width: 23.25rem;
      margin-left: 357px;
    }
    &__table .carousel{
      width: 23.25rem;
    }
    &__table .carousel__viewport{
      width: 23.25rem;
    }
    & .carousel__viewport{
      width: 23.25rem;
    }
    &__list {
      gap: 1.875rem;
    }
    &__element {
      width: 21.375rem;
      height: 12rem;
    }
    &__image {
      width: 21.375rem;
      height: 12rem;
    }
    &__model {
      font-size: 1.5rem;
      margin: 0 0 0.5rem;
      width: 100%;
      line-height: 1.5;
      text-align: center;
    }
    &__price {
      font-size: 1.5rem;
      margin: 0 0 5px;
    }
    &__description {
      font-size: 0.75rem;
    }
    &__main {
      margin-bottom: 3.125rem;
    }
    &__side {
      max-width: 257px;
      margin-left: 6.25rem;
    }
    &__side--header {
      font-size: 1.5rem;
      height: 1.875rem;
      padding: 0;
    }
    &__main--right {
      gap: 4.375rem;
    }
    &__side--element {
      height: 1.9375rem;
      padding-top: 0.875rem;
      padding-left: 1.875rem;
      font-size: 0.875rem;
      width: 15.125rem;
    }
    &__side--table {
      display: flex;
      flex-direction: column;
    }
    &__side--table-element {
      height: 45px;
      align-items: center;
      font-family: var(--primary-font);
      font-size: 0.875rem;
      padding-left: 1.875rem;
      text-wrap: balance;
    }
    &__side--table-element:nth-child(odd) {
      background-color: #f0f1f0;
    }
    &__side--table-element:nth-child(even) {
      background-color: var(--secondary-color);
    }
    &__table {
      width: 100%;
      display: flex;
      gap: 1.875rem;
      background: linear-gradient(#f0f1f0 50%, white 50%);
      background-size: 100% 5.625rem;
    }
    &__row {
      display: flex;
      flex-direction: column;
      width: 21.375rem;
    }
    &__data {
      height: 45px;
      padding: 0;
      align-items: center;
      justify-content: center;
      font-family: var(--primary-font);
      font-size: 0.875rem;
    }
    &__data:hover{
      font-weight: 700;
    }
    &__data:nth-child(odd) {
      background-color: #f0f1f0;
    }
  }
  .blur-right {
    position: absolute;
    height: 100%;
    width: 1.875rem;
    top: 0;
    right: 0;
    opacity: 50%;
    background: linear-gradient(
      to right,
      transparent 0%,
      var(--secondary-color) 100%
    );
    z-index: 5;
  }
  .blur-left {
    position: absolute;
    height: 24rem;
    top: -24rem;
    left: 0;
    opacity: 50%;
    background: linear-gradient(
      to left,
      transparent 0%,
      var(--secondary-color) 100%
    );
    width: 1.875rem;
  }
  .left--blockage {
    position: absolute;
    height: calc(100% + 24rem);
    background-color: var(--secondary-color);
    width: 6.25rem;
    top: -24rem;
    left: -6.25rem;
    z-index: 3;
  }
}
@media screen and (min-width: 1480px) {
  .comparing {
    &__list--wrapper-up {
      height: 12rem;
      width: 23.25rem;
    }
    &__side--header {
      margin: 1.25rem 0;
    }
    &__icon--next{
      right: -385px;
    }
    &__icon--prev{
      left: -13px;
    }
  }
  .blur-right {
    width: 6.25rem;
  }
  .blur-left {
    width: 6.25rem;
  }
  
}
