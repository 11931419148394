.specials {
  // margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 37px;
  opacity: 0;
  &__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // margin: 0 0 3.25rem 0;
    padding: 0;
    width: 100%;
    gap: 1.875rem;
  }
  &__element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
    width: 100%;
    height: 100%;
  }
  &__element img {
    width: 100%;
    height: 100%;
  }
  &__description {
    font-family: var(--primary-font);
    font-size: 1.125rem;
    margin: 0;
    text-align: center;
    text-wrap: balance;
  }
  &__description br {
    display: none;
  }
  &__animation{
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }
}
@media screen and (min-width: 980px) {
  .specials {
    padding: 0 6.25rem;
    // margin-top: 6.25rem;
    &__list {
      flex-direction: row;
      // margin: 0 0 6.25rem 0;
    }
    &__element {
      gap: 1.875rem;
    }
    &__description {
      height: 3.75rem;
    }
  }
}
@media screen and (min-width: 1450px) {
  .specials {
    &__description {
      font-size: 1.5rem;
    }
  }
}
