.financing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 37px;
  position: relative;
  overflow: hidden;
  opacity: 0;
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 3.125rem;
    justify-content: space-between;
    gap: 1.875rem;
  }
  &__form--container {
    width: 100%;
  }
  &__select {
    width: 100%;
    height: 3.125rem;
    border: 1px solid var(--primary-color);
    border-radius: 0;
    font-family: var(--primary-font);
    font-size: 0.875rem;
    color: var(--primary-color);
    outline: none;
    position: relative;
  }
  &__input {
    width: 100%;
    height: 3.125rem;
    box-sizing: border-box;
    border: 1px solid var(--primary-color);
    font-family: var(--primary-font);
    font-size: 0.875rem;
    color: var(--primary-color);
    padding-left: 1rem;
    outline: none;
    position: relative;
    display: block;
  }
  &__input::placeholder{
    color: var(--primary-color);
    font-size: 0.875rem;
  }
  &__label {
    position: relative;
    display: block;
  }
  &__label::after {
    font-family: var(--primary-font);
    font-size: 0.875rem;
    content: attr(data-label);
    font-weight: 400;
    position: absolute;
    right: 1.75rem;
    top: 17px;
  }
  &__text--form {
    font-family: var(--primary-font);
    font-size: 0.875rem;
    color: var(--primary-color);
    margin: 0 0 0.75rem 0;
  }
  &__option {
    padding: 0.625rem;
  }
  &__select--icon {
    position: absolute;
    width: 0.9375rem;
    height: 0.5rem;
  }
  &__select--image {
    width: 0.9375rem;
    height: 0.5rem;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 403px;
    margin: 43px 0 3.125rem 0;
    gap: 1.25rem;
  }
  &__list {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    gap: 0.625rem;
  }
  &__wrap {
    display: flex;
    justify-content: center;
    width: 236px;
    height: 403px;
    position: relative;
  }
  &__element {
    height: 403px;
    width: 14.75rem;
    position: relative;
    cursor: pointer;
    transition: ease-in-out 0.2s;
  }
  &__element:hover &__block{
    background-color: var(--blue-color);
    transition: ease-in-out 0.2s;
  }
  &__element:hover &__inside{
    border-color: var(--blue-color);
    transition: ease-in-out 0.2s;
  }
  &__element:hover &__header{
    color: var(--secondary-color);
    transition: ease-in-out 0.2s;
  }
  &__block {
    background-color: var(--form-color);
    height: 3.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14.75rem;
    transition: ease-in-out 0.2s;
  }
  &__header {
    margin: 0;
    font-family: var(--primary-font);
    font-size: 1.125rem;
    color: black;
    text-align: center;
    transition: ease-in-out 0.2s;
  }
  &__inside {
    height: 347px;
    background-color: var(--secondary-color);
    border: 0.375rem solid var(--form-color);
    border-top: 0;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    padding: 0 0.625rem;
    transition: ease-in-out 0.2s;
  }
  &__block-activated{
    background-color: var(--blue-color);
  }
  &__inside-activated{
    border-color: var(--blue-color);
  }
  &__header-activated{
    color: var(--secondary-color);
  }
  &__text {
    margin: 0;
    text-align: center;
    font-family: var(--primary-font);
    font-size: 0.875rem;
    color: var(--primary-color);
    line-height: 1;
  }
  &__icon {
    width: 1.25rem;
    height: 1.25rem;
  }
  &__icon--image {
    width: 1.25rem;
    height: 1.25rem;
  }
  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.625rem;
  }
  &__icon--prev{
    left: -1.875rem;
    top: 50%;
  }
  &__icon--next{
    right: -1.875rem;
    top: 50%;
  }
  &__blur--left{
    position: absolute;
    height: 403px;
    width: 1.875rem;
    top: 8.875rem;
    left: 0;
    background: linear-gradient(
      to left,
      transparent 0%,
      var(--secondary-color) 100%
    );
    z-index: 5;
  }
  &__blur--right{
    position: absolute;
    height: 403px;
    width: 1.875rem;
    top: 8.875rem;
    right: 0;
    background: linear-gradient(
      to right,
      transparent 0%,
      var(--secondary-color) 100%
    );
    z-index: 5;
  }
  &__animation{
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }
}
@media screen and (min-width: 980px) {
  .financing {
    padding: 0 6.25rem;
    overflow: visible;
    &__wrapper {
      flex-direction: row;
    }
    &__container {
      height: 345px;
      width: 100%;
    }
    &__wrap {
      width: 100%;
      height: 345px;
    }
    &__list {
      align-items: center;
      justify-content: space-between;
      height: 345px;
      width: 100%;
      gap: 1.875rem;
    }
    &__element {
      height: 100%;
      width: 100%;
    }
    &__block {
      height: 5rem;
      width: 100%;
    }
    &__header {
      font-size: 1.5rem;
    }
    &__inside {
      height: 253px;
    }
    &__text {
      font-size: 0.75rem;
    }
    &__icon {
      width: 0.9375rem;
      height: 0.9375rem;
    }
    &__icon--image {
      width: 0.9375rem;
      height: 0.9375rem;
    }
  }
  .none {
    display: none;
  }
}
@media screen and (min-width: 1450px) {
  .financing {
    &__text {
      font-size: 0.875rem;
    }
    &__icon {
      width: 1.25rem;
      height: 1.25rem;
    }
    &__icon--image {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}
