.multiselect {
  font-family: var(--primary-font);
  font-size: 0.875rem;
  &-caret {
    mask-image: url("../assets/Icons/arrow_down.png");
    background-color: var(--blue-color);
    width: 0.875rem;
    height: 0.875rem;
  }
  &-dropdown {
    max-height: 18.75rem;
    border: 1px solid var(--primary-color);
    border-radius: 0;
    border-top: 0;
  }
  &-clear {
    display: none;
  }
  &-option.is-selected {
    background: var(--blue-color);
  }
  &-option.is-selected.is-pointed {
    background: var(--blue-color);
  }
  &-option.is-selected:hover {
    background: var(--blue-color);
  }
  &.is-active {
    box-shadow: none;
    border-radius: 0;
    border: 1px solid var(--primary-color);
  }
  &-option {
    border-top: 1px solid var(--form-color);
    height: 3.125rem;
    font-size: 0.875rem;
  }
}
