.button__gray {
  background-color: var(--blue-color);
  color: var(--secondary-color);
  height: 35px;
  padding: 0px 41px;
  font-family: var(--primary-font);
  font-size: 14px;
  border: none;
  cursor: pointer;
  letter-spacing: 1.1;
  align-self: center;
}
.button__gray:nth-child(2) {
  background-color: var(--primary-color);
  height: 57px;
  padding: 0px 1.25rem;
}
@media screen and (min-width: 980px) {
  .button__gray {
    background-color: var(--primary-color);
    height: 3.75rem;
    padding: 0px 2.5rem;
    font-size: 1.125rem;
  }
}
