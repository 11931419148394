.configuration {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 49px;
  margin-bottom: 50px;
  padding: 0 37px;
  opacity: 0;
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__description {
    text-align: center;
    text-wrap: balance;
    font-family: var(--primary-font);
    font-size: 20px;
    width: 100%;
  }
  &__list {
    margin: 0 0 3.5rem 0;
    padding: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none;
    row-gap: 2.125rem;
  }
  &__element {
    width: 50%;
  }
  &__table {
    width: 19.875rem;
    margin-top: 25px;
    margin-bottom: 35px;
  }
  &__container--mobile {
    width: 100%;
    margin-top: 29px;
  }
  &__block--mobile {
    padding: 0.8rem 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__block--mobile:nth-child(odd) {
    background-color: #f0f1f0;
  }
  &__header--mobile {
    margin: 0;
    font-family: var(--primary-font);
    font-size: 0.8125rem;
    color: var(--primary-color);
    text-wrap: balance;
  }
  &__text--mobile {
    margin: 0;
    font-family: var(--primary-font);
    font-size: 0.8125rem;
    color: black;
    max-width: 90%;
  }
  &__text--mobile-price {
    margin: 0;
    font-family: var(--primary-font);
    font-size: 0.8125rem;
    color: var(--primary-color);
    max-width: 90%;
  }
  &__wrapper--mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 70%;
  }
  &__animation{
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }
}
.element {
  &__block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__block svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  &__block svg image {
    width: 100%;
    height: 100%;
  }
  &__info {
    margin: 1.25rem 0 0.25rem 0;
    font-family: var(--primary-font);
    color: var(--primary-color);
    font-size: 0.75rem;
  }
  &__detail {
    margin: 0;
    font-family: var(--primary-font);
    color: black;
    font-size: 1.125rem;
    text-align: center;
    text-wrap: wrap;
  }
}
.table {
  &__row {
    text-align: left;
    height: 39px;
  }
  &__row:first-child {
    color: var(--primary-color);
  }
  &__row:nth-child(even) {
    background-color: #f0f1f0;
  }
  &__data:first-child {
    width: 3.125rem;
    text-align: center;
  }
  &__data:last-child {
    width: 5.625rem;
  }
  &__data {
    font-family: var(--primary-font);
    font-size: 0.875rem;
    border: none;
    font-weight: 500;
    &--bold {
      font-weight: 700;
    }
  }
}
@media screen and (min-width: 520px) {
  .configuration {
    &__table {
      min-width: 26.25rem;
      margin-bottom: 3.75rem;
    }
  }
}
@media screen and (min-width: 980px) {
  .configuration {
    margin-top: 4rem;
    margin-bottom: 6.25rem;
    &__list {
      margin: 1.25rem 0 4.375rem 0;
      padding: 0 5rem;
      flex-wrap: nowrap;
      row-gap: 0;
    }
    &__table {
      width: 45rem;
    }
  }
  .element {
    &__info {
      margin: 2.25rem 0 0.25rem 0;
      font-size: 0.875rem;
    }
    &__detail {
      font-size: 1.5rem;
      text-wrap: balance;
      text-align: center;
    }
  }
}
@media screen and (min-width: 1450px) {
  .configuration {
    margin-top: 8rem;
    &__table {
      width: 60.125rem;
      margin-bottom: 4.375rem;
    }
  }
  .table {
    &__data:first-child {
      width: 6.25rem;
    }
    &__data:last-child {
      width: 9.375rem;
    }
  }
}
