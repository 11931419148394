.accesorries {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;
    margin-bottom: 4.25rem;
    position: relative;
  }
  &__list {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    gap: 30px;
  }
  &__wrapper {
    width: 14.75rem;
    margin: 0px 5px;
  }
  &__wrapper .carousel__viewport {
    width: 266px;
  }
  &__wrapper .carousel {
    width: 266px;
  }
  &__element {
    width: 14.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__image {
    width: 14.75rem;
    height: 134px;
  }
  &__info {
    font-family: var(--primary-font);
    font-size: 1.125rem;
    text-wrap: balance;
    color: black;
    margin: 1.25rem 0rem 0.625rem;
    text-align: center;
    text-wrap: wrap;
    height: 3.5rem;
    max-width: 80%;
    display: flex;
    align-items: center;
  }
  &__price {
    font-family: var(--primary-font);
    font-size: 0.875rem;
    color: var(--primary-color);
    margin: 0;
    text-align: center;
    text-wrap: wrap;
  }
  &__prev {
    position: absolute;
    top: 50%;
    left: 0;
  }
  &__next {
    position: absolute;
    top: 50%;
    right: -31px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 980px) {
  .accesorries {
    &__container {
      margin-top: 3.75rem;
      margin-bottom: 7.5rem;
      height: 18.25rem;
    }
    &__wrapper {
      width: 44.625rem;
      height: 18.25rem;
    }
    &__wrapper .carousel__viewport {
      width: 23.25rem;
    }
    &__wrapper .carousel {
      width: 23.25rem;
    }
    &__element {
      width: 21.375rem;
    }
    &__image {
      width: 21.375rem;
      height: 193px;
    }
    &__info {
      font-size: 1.5rem;
    }
    &__prev {
      left: -7px;
    }
    &__next {
      right: -38px;
    }
  }
}
@media screen and (min-width: 1450px) {
  .accesorries {
    &__wrapper {
      width: 1086px;
    }
  }
}
@media screen and (min-width: 1800px) {
  .accesorries {
    &__wrapper {
      width: 1458px;
    }
  }
}
