.footer {
  background-color: black;
  height: 51.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3.125rem;
  &__container {
    width: 100%;
    height: 467px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 0;
  }
  &__container:last-of-type {
    height: 357px;
    width: 100%;
  }
  &__block {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    height: 100%;
  }
  &__header {
    font-family: var(--secondary-font);
    color: white;
    font-size: 1.5rem;
    margin: 1.125rem 0 0.8125rem 0;
  }
  &__logo {
    margin-top: 65px;
    margin-left: 0;
    font-family: var(--primary-font);
    font-weight: 100;
    color: var(--secondary-color);
    font-size: 60px;
    text-wrap: nowrap;
  }
  &__text {
    margin: 0;
    color: var(--secondary-color);
    font-family: var(--primary-font);
    font-size: 1rem;
    line-height: 1.6;
    text-wrap: balance;
  }
  &__link {
    cursor: pointer;
    color: var(--blue-color);
  }
  &__text--block {
    display: flex;
    justify-content: space-between;
    width: 12.5rem;
  }
}
@media screen and (min-width: 980px) {
  .footer {
    height: 330px;
    flex-direction: row;
    margin-top: 7.375rem;
    &__container {
      width: 50%;
      height: 330px;
      align-items:normal;
    }
    &__container:last-of-type {
      height: 330px;
      width: 50%;
    }
    &__logo {
      margin-top: 2.75rem;
      width: 17.5rem;
      height: 35px;
      margin-left: 6.25rem;
    }
    &__block {
      width: 50%;
    }
    &__block:first-child{
      margin-left: 6.25rem;
    }
    &__wrapper {
      flex-direction: row;
    }
    &__text--block {
      width: 8.75rem;
    }
  }
}
@media screen and (min-width: 1450px) {
  .footer {
    &__logo {
      width: 395px;
      height: 3.75rem;
    }
    &__text {
      font-size: 1.125rem;
    }
    &__text--block {
      width: 12.5rem;
    }
  }
}
