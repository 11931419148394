.header {
  height: 3.875rem;
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.875rem;
    padding: 0 2.125rem;
  }
  &__logo--one {
    width: 127px;
    height: 2.5rem;
  }
  &__logo--two {
    width: 7.25rem;
    max-height: 90px;
  }
}
header::after {
  content: "";
  display: block;
  background-color: #d6d6d6;
  height: 1px;
  width: 100%;
  opacity: 50%;
}
.headingBlack {
  font-family: var(--primary-font);
  font-size: 1.125rem;
  color: black;
  font-weight: 400;
  text-align: center;
  margin: 0;
  padding: 0 1.25rem;
}
.heading {
  font-family: var(--secondary-font);
  font-size: 1.5rem;
  color: var(--primary-color);
  margin: 0;
  line-height: 1;
  text-align: center;
  font-weight: 100;
  width: fit-content;
  &__wrapper {
    display: flex;
    align-items: center;
    gap: 0.875rem;
    margin-bottom: 2.125rem;
    position: relative;
  }
  &__icon {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
  }
  &__icon image {
    width: 1.25rem;
    height: 1.25rem;
  }
}
@media screen and (min-width: 520px) {
  .header {
    height: 4.375rem;
    &__container {
      height: 4.375rem;
      padding: 0 37px;
    }
    &__logo--one {
      width: 157px;
      height: 3.125rem;
    }
    &__logo--two {
      width: 13.25rem;
    }
  }

}
@media screen and (min-width: 980px) {
  .header {
    height: 6.25rem;
    &__container {
      height: 6.25rem;
      padding: 0 6.25rem;
    }
    &__logo--one {
      width: 233px;
      height: 4.625rem;
    }
    &__logo--two {
      width: 16rem;
    }
  }
  .headingBlack {
    font-size: 1.25rem;
    padding: 0;
  }
  .heading {
    font-size: 33px;
    max-width: 100%;
    &__wrapper {
      margin-bottom: 3.125rem;
    }
  }
}
@media screen and (min-width: 1450px) {
  .headingBlack {
    font-size: 1.5rem;
  }
  .heading {
    font-size: 3rem;
  }
}
