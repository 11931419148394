.slider {
  &__zip {
    --slider-bg: black;
    --slider-connect-bg: var(--blue-color);
    --slider-tooltip-bg: var(--blue-color);
    margin-bottom: 10px;
    width: 96%;
  }
  &__placeholder--image {
    max-width: 70%;
  }
  &__horizontal {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__car--info {
    display: flex;
    flex-direction: column;
    margin-top: 1.875rem;
    padding: 0 2.125rem;
    opacity: 0;
  }
  &__car--name {
    max-width: 13.125rem;
    opacity: 1;
  }
  &__car--client-name {
    font-family: var(--primary-font);
    font-weight: 600;
  }
  &__car--text {
    margin: 0;
    font-size: 1.875rem;
    font-family: var(--secondary-font);
  }
  &__car--text:first-of-type {
    text-wrap: nowrap;
  }
  &__car--text-medium {
    margin: 0;
    font-size: 1.75rem;
    font-family: var(--secondary-font);
  }
  &__car--text-small {
    margin: 0;
    font-size: 1.5rem;
    font-family: var(--secondary-font);
  }
  &__button--container {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    height: 6.875rem;
  }
  &__button {
    height: 3.125rem;
    font-family: var(--primary-font);
    font-size: 0.875rem;
    padding: 0.9375rem 1.25rem 0.9375rem 1.25rem;
    cursor: pointer;
    box-shadow: inset 0 0.3125rem 0.625rem #00000029;
    color: var(--primary-color);
    border: none;
  }
  &__car--block {
    background-color: var(--primary-color);
    max-width: 19.875rem;
    max-height: 113px;
    width: 17.125rem;
    height: 11.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1.625rem;
    margin-top: 1.875rem;
    gap: 5px;
  }
  &__car--block--blue {
    background-color: var(--blue-color);
    max-width: 19.875rem;
    max-height: 113px;
    width: 17.125rem;
    height: 11.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1.625rem;
    margin-top: 1.875rem;
    gap: 5px;
  }
  &__car--block--bordo {
    background-color: #801C62;
    max-width: 19.875rem;
    max-height: 113px;
    width: 17.125rem;
    height: 11.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1.625rem;
    margin-top: 1.875rem;
    gap: 5px;
  }
  &__car--description {
    display: flex;
    align-items: baseline;
    line-height: 1;
    .slider__car--font {
      margin: 0.35rem 0 0 !important;
    }
  }
  &__car--font {
    font-family: var(--primary-font);
    font-size: 0.875rem;
    color: var(--secondary-color);
    margin: 0;
    width: 15.625rem;
  }
  &__car--font:last-child {
    margin: 1.2rem 0 0;
  }
  &__car--price {
    font-size: 1.875rem;
    width: 12rem;
    font-family: var(--secondary-font);
    margin: 0;
    color: var(--secondary-color);
    line-height: 0.9;
  }
  &__thumbnail {
    width: 1108px;
    overflow: hidden;
  }
  &__thumbnail--relative {
    display: none;
    position: relative;
    &-next {
      display: none;
      position: absolute;
      cursor: pointer;
      top: 50px;
      right: -25px;
      image {
        width: 100%;
      }
    }
    &-prev {
      display: none;
      position: absolute;
      top: 50px;
      cursor: pointer;
      left: -25px;
      image {
        width: 100%;
      }
    }
  }
  &__thumbnail--list {
    padding: 0;
    display: flex;
    gap: 20px;
    list-style: none;
    height: 68px;
    margin: 30px 0px;
    position: relative;
    transition: all 0.5s ease-in-out;
  }
  &__thumbnail--element {
    min-width: 121px;
    min-height: 68px;
    box-shadow: 0 0.3125rem 1.25rem #00000065;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1;
    img {
      height: 100%;
      width: auto;
      max-width: 100%;
    }
  }
  &__main--image {
    width: 19.75rem;
    height: 11.125rem;
    background-color: #f0f1f0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    opacity: 0;
    margin-top: 50px;
    &--loader {
      transform: translateY(-100px);
    }
    .carousel {
      width: 100%;
    }
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 11.125rem;
    position: relative;
    margin-left: 4.125rem;
  }
  &__wrapper--gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 11.125rem;
    position: relative;
  }
  &__list--container {
    width: 23.875rem;
    height: 11.125rem;
  }
  &__list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 4.125rem;
  }
  &__element {
    max-width: 23.875rem;
    max-height: 11.125rem;
  }
  &__element video {
    width: 19.75rem;
    height: 11.125rem;
  }
  &__element source {
    width: 19.75rem;
    height: 11.125rem;
  }
  &__icon {
    cursor: pointer;
    z-index: 5;
    width: 0.625rem;
    height: 1.25rem;
    position: absolute;
  }
  &__icon image {
    width: 0.625rem;
    height: 1.25rem;
  }
  &__description {
    font-family: var(--primary-font);
    font-size: 1rem;
    color: black;
    margin: 1.375rem 0 0.375rem 0;
    text-align: center;
    text-wrap: balance;
    padding: 0 6.25rem;
    width: 100%;
  }
  &__info {
    font-family: var(--primary-font);
    font-size: 0.625rem;
    color: var(--primary-color);
    margin: 0;
  }
  &__prev--icon {
    left: -1.25rem;
    top: 50%;
  }
  &__next--icon {
    right: 2.875rem;
    top: 50%;
  }
  &__slide-content {
    width: 100%;
    min-height: 100%;
    padding-right: 4.125rem;
  }
  &__animation {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }
}
.active {
  background-color: black;
  &__thumbnail {
    border: 3px solid var(--blue-color);
    min-width: 121px;
    min-height: 68px;
    color: var(--secondary-color);
    background-color: #f1f1f1;
    &--image {
      height: 100%;
      width: auto;
    }
  }
  &__thumbnail--bordo {
    border: 3px solid #801c62;
    min-width: 121px;
    min-height: 68px;
    background-color: #f1f1f1;
    &--image {
      height: 100%;
      width: auto;
    }
  }
}

.carousel__viewport {
  overflow: visible;
  height: 100%;
}
.carousel__track {
  height: 100%;
}
.gallery__next--icon {
  position: absolute;
  top: 50%;
  right: -0.9rem;
}
.gallery__prev--icon {
  position: absolute;
  top: 50%;
  left: -0.9rem;
}
.sliderCarBlock__container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}
@media screen and (min-width: 550px) {
  .slider {
    &__car--info {
      justify-content: space-between;
      align-items: center;
      padding: 0 6.25rem;
      margin: 1.25rem 0 0 0;
    }
    &__car--name {
      max-width: 17.5rem;
    }
    &__car--text {
      font-size: 2.5rem;
    }
    &__car--text-medium {
      font-size: 2.2rem;
    }
    &__car--text-small {
      font-size: 1.75rem;
    }
    &__main--image {
      width: 26.25rem;
      height: 15rem;
    }
    &__wrapper {
      height: 15rem;
    }
    &__wrapper--gallery {
      height: 15rem;
    }
    &__list--container {
      width: 30.375rem;
      height: 15rem;
    }
    &__element {
      max-width: 30.375rem;
      max-height: 15rem;
    }
    &__element video {
      width: 26.25rem;
      height: 15rem;
    }
    &__element source {
      width: 26.25rem;
      height: 15rem;
    }
    &__description {
      font-size: 1rem;
      margin: 39px 0 0.375rem 0;
    }
    &__info {
      font-size: 0.875rem;
    }
  }
}
@media screen and (min-width: 980px) {
  .sliderCarBlock__container {
    flex-direction: row;
  }
  .slider {
    &__thumbnail--relative {
      display: block;
      &-prev {
        display: block;
      }
      &-next {
        display: block;
      }
    }
    &__car--info {
      flex-direction: row;
    }
    &__button--container {
      height: 137px;
    }
    &__car--block {
      max-width: 18.125rem;
      max-height: 11.25rem;
      width: 18.125rem;
      height: 11.25rem;
      padding-left: 1.5rem;
      gap: 0;
    }
    &__car--font {
      font-size: 1rem;
    }
    &__car--price {
      font-size: 3rem;
      width: 15.625rem;
    }
    &__car--description {
      flex-direction: column;
    }
    &__main--image {
      width: 38.75rem;
      height: 20rem;
    }
    &__wrapper {
      height: 20rem;
    }
    &__wrapper--gallery {
      height: 20rem;
    }
    &__list--container {
      width: 42.875rem;
      height: 20rem;
    }
    &__element {
      max-width: 42.875rem;
      max-height: 20rem;
    }
    &__element video {
      width: 38.75rem;
      height: 20rem;
    }
    &__icon {
      width: 23px;
      height: 2.5rem;
    }
    &__icon image {
      width: 23px;
      height: 2.5rem;
    }
    &__description {
      font-size: 1.25rem;
      margin: 39px 0 0.375rem 0;
    }
    &__prev--icon {
      left: -2.5rem;
    }
    &__next--icon {
      right: 1.625rem;
    }
  }
  .gallery__next--icon {
    position: absolute;
    top: 50%;
    right: -2.5rem;
  }
  .gallery__prev--icon {
    position: absolute;
    top: 50%;
    left: -2.5rem;
  }
}
@media screen and (min-width: 1450px) {
  .slider {
    &__car--name {
      max-width: 407px;
    }
    &__car--text {
      font-size: 3.75rem;
    }
    &__car--text-medium {
      font-size: 2.75rem;
    }
    &__car--text-small {
      font-size: 2rem;
    }
    &__car--block,
    &__car--block--blue,
    &__car--block--bordo {
      max-width: 21.375rem;
      max-height: 12.125rem;
      width: 21.375rem;
      height: 12.125rem;
      padding-left: 2.375rem;
    }
    &__car--price {
      font-size: 3.75rem;
      width: 18.75rem;
    }
    &__main--image {
      width: 66.75rem;
      height: 37.5rem;
    }
    &__wrapper {
      height: 37.5rem;
    }
    &__wrapper--gallery {
      height: 37.5rem;
    }
    &__list--container {
      width: 70.875rem;
      height: 37.5rem;
    }
    &__element {
      max-width: 70.875rem;
      max-height: 37.5rem;
    }
    &__element video {
      width: 66.75rem;
      height: 37.5rem;
    }
  }
}
@media screen and (min-width: 1650px) {
  .slider {
    .carousel__slide {
      padding: 0 294px;
    }
  }
}
