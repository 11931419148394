.intro__video {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.intro__video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.csc__logo {
  position: absolute;
  bottom: 1.25rem;
  width: 111px;
  height: 2.125rem;
  transition: all 1s ease-in-out;
  & image {
    width: 111px;
    height: 2.125rem;
  }
}
.balance {
  text-wrap: balance;
  max-width: 11.25rem;
  margin-bottom: 1.875rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}
.login {
  &__image {
    min-width: 20.375rem;
    min-height: 9.875rem;
    max-height: 14.875rem;
  }
  &__image img {
    height: 100%;
    width: 100%;
  }
  &__main {
    height: 100vh;
    width: 100vw;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: scroll;
  }
  &__container {
    max-width: 50rem;
    min-width: 20.375rem;
    max-height: 100vh;
    background-color: var(--secondary-color);
    display: flex;
    flex-direction: column;
    margin: 0 12.25rem;
    transform: translateY(12.5rem);
  }
  &__container--transform{
    transition-timing-function: ease-in-out;
    transition: transform 1s;
    transform: translateY(0);
  }
  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  &__logo {
    height: 4.375rem;
    margin: 47px 0 2.25rem;
  }
  &__header {
    font-family: var(--primary-font);
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0 0 0.625rem;
    text-transform: uppercase;
  }
  &__text--step {
    font-family: var(--primary-font);
    font-size: 0.875rem;
    color: var(--primary-color);
    margin: 0 0 1.0625rem;
    text-align: center;
  }
  &__text {
    font-family: var(--primary-font);
    font-size: 0.875rem;
    color: var(--primary-color);
    margin: 0;
    text-align: center;
  }
  &__text:first-of-type {
    margin-bottom: 23px;
  }
  &__label {
    font-family: var(--primary-font);
    font-size: 0.875rem;
    color: var(--primary-color);
    margin: 1.9375rem 0 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  &__label--error {
    line-height: 2;
    color: #FF2121;
    font-size: 0.875rem;
    font-family: var(--primary-font);
  }
  &__input {
    height: 3.125rem;
    width: 16.25rem;
    padding: 1.125rem 0;
    text-align: center;
    transition: all 0.1s ease-in-out;
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
    outline: none;
  }
  &__input::placeholder {
    letter-spacing: 0.1rem;
    color: black;
    text-align: center;
    font-size: 1.2rem;
    opacity: 0.5;
  }
  &__button--black {
    height: 3.75rem;
    width: 7.875rem;
    cursor: pointer;
    font-family: var(--primary-font);
    font-size: 1.125rem;
    margin-bottom: 168px;
    background-color: black;
    color: white;
    margin-top: 1.5rem;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    border: 1px solid black;
    &:hover {
      background-color: var(--secondary-color);
      color: black;
      transition: all 0.2s ease-in-out;
      border-radius: 50px;
    }
  }
  &__button--bordo {
    height: 3.75rem;
    width: 7.875rem;
    cursor: pointer;
    font-family: var(--primary-font);
    background-color: #801C62;
    color: white;
    font-size: 1.125rem;
    margin-bottom: 168px;
    margin-top: 1.5rem;
    border: 1px solid #801C62;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: white;
      color: #801C62;
      transition: all 0.2s ease-in-out;
      border-radius: 50px;
    }
  }
  &__second--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
  }
  &__second--container-transform{
    transition-timing-function: ease-in-out;
    transition: opacity 1s;
    opacity: 1;
  }
  &__text--confirm {
    margin: 0 0 0.625rem;
    font-family: var(--primary-font);
    font-size: 0.75rem;
    font-weight: 600;
  }
  &__description {
    margin: 0 0 31px;
    font-family: var(--primary-font);
    font-size: 0.75rem;
    color: var(--primary-color);
    width: 15.625rem;
    text-align: center;
    text-wrap: balance;
  }
  &__helper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all 1s ease-in-out;
  }
  &__helper-transform {
    margin-bottom: 100px;
    transition: all 1s ease-in-out;
  }
  &__send--again {
    margin: 0;
    font-family: var(--primary-font);
    font-size: 0.75rem;
    text-decoration: underline;
    color: var(--blue-color);
    cursor: pointer;
    font-weight: 600;
    margin-bottom: 31px;
    transform: translateY(-30px);
    text-transform: uppercase;
  }
  &__label--confirm {
    font-family: var(--primary-font);
    font-size: 0.875rem;
    color: var(--primary-color);
    margin: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 17px;
    transform: translateY(-45px);
  }
  &__input--container {
    display: flex;
    height: 3.125rem;
    gap: 0.3125rem;
    margin-bottom: 1.375rem;
  }
  &__input--digit {
    width: 2.75rem;
    padding: 0;
    height: 2.75rem;
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
    outline: none;
    display: flex;
    align-items: center;
    text-align: center;
    font-family: var(--primary-font);
    font-size: 1.625rem;
  }
  &__button--confirm {
    height: 3.75rem;
    width: 11.125rem;
    color: var(--secondary-color);
    background-color: black;
    border: 1px solid black;
    cursor: pointer;
    font-family: var(--primary-font);
    font-size: 1.125rem;
    transform: translateY(-45px);
    margin-bottom: 123px;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    &:hover {
      background-color: var(--secondary-color);
      color: black;
      transition: all 0.2s ease-in-out;
    }
  }
  &__sendAgain-transform{
    transition-timing-function: ease-in-out;
    transition: transform 0.5s;
    transform: translateY(0);
  }
}
@media screen and (min-width: 1100px) {
  .login {
    &__container {
      max-width: 1418px;
      min-width: 1100px;
      height: 464px;
      flex-direction: row;
      margin: 0;
    }
    &__image {
      height: 100%;
      max-height: 100%;
      max-width: 1013px;
    }
    &__image img {
      width: 720px;
      height: 464px;
    }
    &__block {
      min-width: 405px;
      height: 100%;
    }
    &__input--digit {
      width: 3.125rem;
      height: 3.125rem;
    }
    &__button--black, &__button--bordo, &__button--confirm{
      margin-bottom: 0;
    }
    &__text:first-of-type {
      margin-bottom: 1.25rem;
    }
    &__logo{
      margin: 20px 0 0.25rem;
      scale: 0.8;
    }
    &__label{
      margin: 1.5rem 0 0;
    }
    &__input{
      height: 2.5rem;
      font-size: 18px;
      width: 12rem;
    }
  }
}
@media screen and (min-width: 1410px) {
  .login{
    &__container{
      height: 651px;
    }
    &__image{
      img{
        width: 100%;
        height: 100%;
      }
    }
    &__logo{
      margin: 47px 0 2.25rem;
      scale: 1;
    }
    &__text:first-of-type {
      margin-bottom: 3.75rem;
    }
    &__text{
      font-size: 1rem;
    }
    &__label{
      margin: 1.9375rem 0 0;
      font-size: 1rem;
    }
    &__input{
      height: 3.125rem;
      padding: 1.75rem 0;
      font-size: 24px;
      width: 16.25rem;
    }
    &__text--confirm {
      font-size: 1rem;
    }
    &__description, &__send--again, &__label--confirm {
      font-size: 0.9rem;
    }
  }
  .balance {
    max-width: 13rem;
  }
}