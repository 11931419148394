.header {
  height: 3.875rem;
}

.header__container {
  justify-content: space-between;
  align-items: center;
  height: 3.875rem;
  padding: 0 2.125rem;
  display: flex;
}

.header__logo--one {
  width: 127px;
  height: 2.5rem;
}

.header__logo--two {
  width: 7.25rem;
  max-height: 90px;
}

header:after {
  content: "";
  opacity: .5;
  background-color: #d6d6d6;
  width: 100%;
  height: 1px;
  display: block;
}

.headingBlack {
  font-family: var(--primary-font);
  color: #000;
  text-align: center;
  margin: 0;
  padding: 0 1.25rem;
  font-size: 1.125rem;
  font-weight: 400;
}

.heading {
  font-family: var(--secondary-font);
  color: var(--primary-color);
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 100;
  line-height: 1;
}

.heading__wrapper {
  align-items: center;
  gap: .875rem;
  margin-bottom: 2.125rem;
  display: flex;
  position: relative;
}

.heading__icon {
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
}

.heading__icon image {
  width: 1.25rem;
  height: 1.25rem;
}

@media screen and (width >= 520px) {
  .header {
    height: 4.375rem;
  }

  .header__container {
    height: 4.375rem;
    padding: 0 37px;
  }

  .header__logo--one {
    width: 157px;
    height: 3.125rem;
  }

  .header__logo--two {
    width: 13.25rem;
  }
}

@media screen and (width >= 980px) {
  .header {
    height: 6.25rem;
  }

  .header__container {
    height: 6.25rem;
    padding: 0 6.25rem;
  }

  .header__logo--one {
    width: 233px;
    height: 4.625rem;
  }

  .header__logo--two {
    width: 16rem;
  }

  .headingBlack {
    padding: 0;
    font-size: 1.25rem;
  }

  .heading {
    max-width: 100%;
    font-size: 33px;
  }

  .heading__wrapper {
    margin-bottom: 3.125rem;
  }
}

@media screen and (width >= 1450px) {
  .headingBlack {
    font-size: 1.5rem;
  }

  .heading {
    font-size: 3rem;
  }
}

:root {
  --primary-color: #909090;
  --secondary-color: white;
  --blue-color: #3376cf;
  --form-color: #f0f1f0;
  --primary-font: "Corporate S Pro";
  --secondary-font: "Corporate A Condensed W07 Rg";
}

.accesorries {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.accesorries__container {
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
  margin-bottom: 4.25rem;
  display: flex;
  position: relative;
}

.accesorries__list {
  gap: 30px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.accesorries__wrapper {
  width: 14.75rem;
  margin: 0 5px;
}

.accesorries__wrapper .carousel__viewport, .accesorries__wrapper .carousel {
  width: 266px;
}

.accesorries__element {
  flex-direction: column;
  align-items: center;
  width: 14.75rem;
  display: flex;
}

.accesorries__image {
  width: 14.75rem;
  height: 134px;
}

.accesorries__info {
  font-family: var(--primary-font);
  text-wrap: balance;
  color: #000;
  text-align: center;
  text-wrap: wrap;
  align-items: center;
  max-width: 80%;
  height: 3.5rem;
  margin: 1.25rem 0 .625rem;
  font-size: 1.125rem;
  display: flex;
}

.accesorries__price {
  font-family: var(--primary-font);
  color: var(--primary-color);
  text-align: center;
  text-wrap: wrap;
  margin: 0;
  font-size: .875rem;
}

.accesorries__prev {
  position: absolute;
  top: 50%;
  left: 0;
}

.accesorries__next {
  position: absolute;
  top: 50%;
  right: -31px;
}

.accesorries__content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (width >= 980px) {
  .accesorries__container {
    height: 18.25rem;
    margin-top: 3.75rem;
    margin-bottom: 7.5rem;
  }

  .accesorries__wrapper {
    width: 44.625rem;
    height: 18.25rem;
  }

  .accesorries__wrapper .carousel__viewport, .accesorries__wrapper .carousel {
    width: 23.25rem;
  }

  .accesorries__element {
    width: 21.375rem;
  }

  .accesorries__image {
    width: 21.375rem;
    height: 193px;
  }

  .accesorries__info {
    font-size: 1.5rem;
  }

  .accesorries__prev {
    left: -7px;
  }

  .accesorries__next {
    right: -38px;
  }
}

@media screen and (width >= 1450px) {
  .accesorries__wrapper {
    width: 1086px;
  }
}

@media screen and (width >= 1800px) {
  .accesorries__wrapper {
    width: 1458px;
  }
}

.button__gray {
  background-color: var(--blue-color);
  color: var(--secondary-color);
  font-family: var(--primary-font);
  cursor: pointer;
  letter-spacing: 1.1px;
  border: none;
  align-self: center;
  height: 35px;
  padding: 0 41px;
  font-size: 14px;
}

.button__gray:nth-child(2) {
  background-color: var(--primary-color);
  height: 57px;
  padding: 0 1.25rem;
}

@media screen and (width >= 980px) {
  .button__gray {
    background-color: var(--primary-color);
    height: 3.75rem;
    padding: 0 2.5rem;
    font-size: 1.125rem;
  }
}

.comparing {
  cursor: grab;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  display: flex;
  position: relative;
}

.comparing .carousel__track {
  margin: 0;
}

.comparing .carousel__slide {
  display: block;
}

.comparing__table .carousel, .comparing__table .carousel__viewport, .comparing .carousel__viewport {
  width: 169px;
}

.comparing__header {
  font-family: var(--secondary-font);
  text-align: center;
  margin: 1.875rem 0;
  font-size: 1.875rem;
  line-height: 1;
}

.comparing__list--block-up {
  width: 100%;
  height: 100%;
}

.comparing__list--container {
  justify-content: space-between;
  align-items: center;
  gap: .375rem;
  height: 87px;
  margin-left: 7.375rem;
  display: flex;
}

.comparing__list--wrapper-up {
  width: 9.625rem;
  height: 87px;
  position: relative;
}

.comparing__list {
  gap: .625rem;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.comparing__element {
  flex-direction: column;
  align-items: center;
  width: 9.625rem;
  height: 87px;
  display: flex;
}

.comparing__image {
  width: 9.625rem;
  height: 87px;
}

.comparing__list--wrapper-down {
  width: 19.875rem;
  height: 87px;
}

.comparing__model {
  font-family: var(--secondary-font);
  text-wrap: balance;
  margin: .625rem 0 0;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.4;
}

.comparing__price {
  font-family: var(--secondary-font);
  text-wrap: balance;
  color: #909090;
  margin: 0 0 .25rem;
  font-size: 1.125rem;
  font-weight: 600;
}

.comparing__description {
  font-family: var(--primary-font);
  color: #909090;
  margin: 0;
  font-size: .625rem;
}

.comparing__main {
  width: 100%;
  margin-bottom: 39px;
  display: flex;
}

.comparing__side {
  z-index: 3;
  max-width: 7.375rem;
  margin-left: 0;
  position: relative;
}

.comparing__side--list {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.comparing__side--element:nth-child(odd) {
  background-color: #f0f1f0;
}

.comparing__side--element:nth-child(2n) {
  background-color: var(--secondary-color);
}

.comparing__side--element {
  font-family: var(--primary-font);
  width: 6.125rem;
  height: 35px;
  padding-top: 25px;
  padding-left: 1.25rem;
  font-size: .625rem;
}

.comparing__side--header {
  font-family: var(--primary-font);
  text-wrap: nowrap;
  height: 1.25rem;
  margin: 1.25rem 0;
  padding-left: 1.25rem;
  font-size: 1.125rem;
}

.comparing__main--right {
  flex-direction: column;
  gap: 3.75rem;
  display: flex;
}

.comparing__main-right .carousel {
  width: 23.25rem;
}

.comparing__side--table {
  flex-direction: column;
  max-width: 17rem;
  display: flex;
}

.comparing__side--table-element {
  font-family: var(--primary-font);
  text-wrap: balance;
  align-items: center;
  height: 3.75rem;
  padding-left: 1.25rem;
  font-size: .625rem;
  display: flex;
}

.comparing__side--table-element:nth-child(odd) {
  background-color: #f0f1f0;
}

.comparing__side--table-element:nth-child(2n) {
  background-color: var(--secondary-color);
}

.comparing__table {
  background: linear-gradient(#f0f1f0 50%, #fff 50%) 0 0 / 100% 7.5rem;
  gap: .625rem;
  width: 100%;
  height: 100%;
  display: flex;
}

.comparing__row {
  flex-direction: column;
  width: 9.625rem;
  display: flex;
}

.comparing__data {
  font-family: var(--primary-font);
  justify-content: center;
  align-items: center;
  height: 3.75rem;
  padding: 0;
  font-size: .625rem;
  display: flex;
}

.comparing__data:nth-child(odd) {
  background-color: #f0f1f0;
}

.comparing__icon--next {
  position: absolute;
  top: 50%;
  right: -13px;
}

.comparing__icon--prev {
  position: absolute;
  top: 50%;
  left: -13px;
}

.no-display, .is-hidden {
  display: none;
}

@media screen and (width >= 980px) {
  .is-hidden {
    display: block;
  }

  .comparing {
    cursor: auto;
  }

  .comparing__header {
    margin: 3.125rem 0;
    font-size: 3.75rem;
  }

  .comparing__list--container {
    height: 12rem;
    margin-left: 0;
  }

  .comparing__list--container:nth-child(odd) {
    z-index: 5;
    background-color: #fff;
    height: 120px;
    padding-bottom: 20px;
    transition: all .5s ease-in-out;
    position: sticky;
    top: 0;
  }

  .comparing__list--gradient {
    background: linear-gradient(#fff 85%, #0000001a 90%, #0000 100%);
    transition: all .5s ease-in-out;
  }

  .comparing__list--wrapper-down {
    width: 23.25rem;
    height: 87px;
    margin-left: 357px;
  }

  .comparing__list--wrapper-up {
    width: 23.25rem;
    height: 12rem;
    margin-left: 357px;
  }

  .comparing__table .carousel, .comparing__table .carousel__viewport, .comparing .carousel__viewport {
    width: 23.25rem;
  }

  .comparing__list {
    gap: 1.875rem;
  }

  .comparing__element, .comparing__image {
    width: 21.375rem;
    height: 12rem;
  }

  .comparing__model {
    text-align: center;
    width: 100%;
    margin: 0 0 .5rem;
    font-size: 1.5rem;
    line-height: 1.5;
  }

  .comparing__price {
    margin: 0 0 5px;
    font-size: 1.5rem;
  }

  .comparing__description {
    font-size: .75rem;
  }

  .comparing__main {
    margin-bottom: 3.125rem;
  }

  .comparing__side {
    max-width: 257px;
    margin-left: 6.25rem;
  }

  .comparing__side--header {
    height: 1.875rem;
    padding: 0;
    font-size: 1.5rem;
  }

  .comparing__main--right {
    gap: 4.375rem;
  }

  .comparing__side--element {
    width: 15.125rem;
    height: 1.9375rem;
    padding-top: .875rem;
    padding-left: 1.875rem;
    font-size: .875rem;
  }

  .comparing__side--table {
    flex-direction: column;
    display: flex;
  }

  .comparing__side--table-element {
    font-family: var(--primary-font);
    text-wrap: balance;
    align-items: center;
    height: 45px;
    padding-left: 1.875rem;
    font-size: .875rem;
  }

  .comparing__side--table-element:nth-child(odd) {
    background-color: #f0f1f0;
  }

  .comparing__side--table-element:nth-child(2n) {
    background-color: var(--secondary-color);
  }

  .comparing__table {
    background: linear-gradient(#f0f1f0 50%, #fff 50%) 0 0 / 100% 5.625rem;
    gap: 1.875rem;
    width: 100%;
    display: flex;
  }

  .comparing__row {
    flex-direction: column;
    width: 21.375rem;
    display: flex;
  }

  .comparing__data {
    font-family: var(--primary-font);
    justify-content: center;
    align-items: center;
    height: 45px;
    padding: 0;
    font-size: .875rem;
  }

  .comparing__data:hover {
    font-weight: 700;
  }

  .comparing__data:nth-child(odd) {
    background-color: #f0f1f0;
  }

  .blur-right {
    opacity: .5;
    background: linear-gradient(to right, transparent 0%, var(--secondary-color) 100%);
    z-index: 5;
    width: 1.875rem;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  .blur-left {
    opacity: .5;
    background: linear-gradient(to left, transparent 0%, var(--secondary-color) 100%);
    width: 1.875rem;
    height: 24rem;
    position: absolute;
    top: -24rem;
    left: 0;
  }

  .left--blockage {
    background-color: var(--secondary-color);
    z-index: 3;
    width: 6.25rem;
    height: calc(100% + 24rem);
    position: absolute;
    top: -24rem;
    left: -6.25rem;
  }
}

@media screen and (width >= 1480px) {
  .comparing__list--wrapper-up {
    width: 23.25rem;
    height: 12rem;
  }

  .comparing__side--header {
    margin: 1.25rem 0;
  }

  .comparing__icon--next {
    right: -385px;
  }

  .comparing__icon--prev {
    left: -13px;
  }

  .blur-right, .blur-left {
    width: 6.25rem;
  }
}

.configuration {
  opacity: 0;
  flex-direction: column;
  align-items: center;
  margin-top: 49px;
  margin-bottom: 50px;
  padding: 0 37px;
  display: flex;
}

.configuration__wrapper {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.configuration__description {
  text-align: center;
  text-wrap: balance;
  font-family: var(--primary-font);
  width: 100%;
  font-size: 20px;
}

.configuration__list {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  row-gap: 2.125rem;
  width: 100%;
  margin: 0 0 3.5rem;
  padding: 0;
  list-style: none;
  display: flex;
}

.configuration__element {
  width: 50%;
}

.configuration__table {
  width: 19.875rem;
  margin-top: 25px;
  margin-bottom: 35px;
}

.configuration__container--mobile {
  width: 100%;
  margin-top: 29px;
}

.configuration__block--mobile {
  justify-content: space-between;
  align-items: center;
  padding: .8rem 1.25rem;
  display: flex;
}

.configuration__block--mobile:nth-child(odd) {
  background-color: #f0f1f0;
}

.configuration__header--mobile {
  font-family: var(--primary-font);
  color: var(--primary-color);
  text-wrap: balance;
  margin: 0;
  font-size: .8125rem;
}

.configuration__text--mobile {
  font-family: var(--primary-font);
  color: #000;
  max-width: 90%;
  margin: 0;
  font-size: .8125rem;
}

.configuration__text--mobile-price {
  font-family: var(--primary-font);
  color: var(--primary-color);
  max-width: 90%;
  margin: 0;
  font-size: .8125rem;
}

.configuration__wrapper--mobile {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 70%;
  display: flex;
}

.configuration__animation {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.element__block {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.element__block svg {
  width: 1.5rem;
  height: 1.5rem;
}

.element__block svg image {
  width: 100%;
  height: 100%;
}

.element__info {
  font-family: var(--primary-font);
  color: var(--primary-color);
  margin: 1.25rem 0 .25rem;
  font-size: .75rem;
}

.element__detail {
  font-family: var(--primary-font);
  color: #000;
  text-align: center;
  text-wrap: wrap;
  margin: 0;
  font-size: 1.125rem;
}

.table__row {
  text-align: left;
  height: 39px;
}

.table__row:first-child {
  color: var(--primary-color);
}

.table__row:nth-child(2n) {
  background-color: #f0f1f0;
}

.table__data:first-child {
  text-align: center;
  width: 3.125rem;
}

.table__data:last-child {
  width: 5.625rem;
}

.table__data {
  font-family: var(--primary-font);
  border: none;
  font-size: .875rem;
  font-weight: 500;
}

.table__data--bold {
  font-weight: 700;
}

@media screen and (width >= 520px) {
  .configuration__table {
    min-width: 26.25rem;
    margin-bottom: 3.75rem;
  }
}

@media screen and (width >= 980px) {
  .configuration {
    margin-top: 4rem;
    margin-bottom: 6.25rem;
  }

  .configuration__list {
    flex-wrap: nowrap;
    row-gap: 0;
    margin: 1.25rem 0 4.375rem;
    padding: 0 5rem;
  }

  .configuration__table {
    width: 45rem;
  }

  .element__info {
    margin: 2.25rem 0 .25rem;
    font-size: .875rem;
  }

  .element__detail {
    text-wrap: balance;
    text-align: center;
    font-size: 1.5rem;
  }
}

@media screen and (width >= 1450px) {
  .configuration {
    margin-top: 8rem;
  }

  .configuration__table {
    width: 60.125rem;
    margin-bottom: 4.375rem;
  }

  .table__data:first-child {
    width: 6.25rem;
  }

  .table__data:last-child {
    width: 9.375rem;
  }
}

.financing {
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 37px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.financing__wrapper {
  flex-direction: column;
  justify-content: space-between;
  gap: 1.875rem;
  width: 100%;
  margin-bottom: 3.125rem;
  display: flex;
}

.financing__form--container {
  width: 100%;
}

.financing__select {
  border: 1px solid var(--primary-color);
  font-family: var(--primary-font);
  color: var(--primary-color);
  border-radius: 0;
  outline: none;
  width: 100%;
  height: 3.125rem;
  font-size: .875rem;
  position: relative;
}

.financing__input {
  box-sizing: border-box;
  border: 1px solid var(--primary-color);
  font-family: var(--primary-font);
  color: var(--primary-color);
  outline: none;
  width: 100%;
  height: 3.125rem;
  padding-left: 1rem;
  font-size: .875rem;
  display: block;
  position: relative;
}

.financing__input::placeholder {
  color: var(--primary-color);
  font-size: .875rem;
}

.financing__label {
  display: block;
  position: relative;
}

.financing__label:after {
  font-family: var(--primary-font);
  content: attr(data-label);
  font-size: .875rem;
  font-weight: 400;
  position: absolute;
  top: 17px;
  right: 1.75rem;
}

.financing__text--form {
  font-family: var(--primary-font);
  color: var(--primary-color);
  margin: 0 0 .75rem;
  font-size: .875rem;
}

.financing__option {
  padding: .625rem;
}

.financing__select--icon {
  width: .9375rem;
  height: .5rem;
  position: absolute;
}

.financing__select--image {
  width: .9375rem;
  height: .5rem;
}

.financing__container {
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  height: 403px;
  margin: 43px 0 3.125rem;
  display: flex;
}

.financing__list {
  gap: .625rem;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.financing__wrap {
  justify-content: center;
  width: 236px;
  height: 403px;
  display: flex;
  position: relative;
}

.financing__element {
  cursor: pointer;
  width: 14.75rem;
  height: 403px;
  transition: all .2s ease-in-out;
  position: relative;
}

.financing__element:hover .financing__block {
  background-color: var(--blue-color);
  transition: all .2s ease-in-out;
}

.financing__element:hover .financing__inside {
  border-color: var(--blue-color);
  transition: all .2s ease-in-out;
}

.financing__element:hover .financing__header {
  color: var(--secondary-color);
  transition: all .2s ease-in-out;
}

.financing__block {
  background-color: var(--form-color);
  justify-content: center;
  align-items: center;
  width: 14.75rem;
  height: 3.125rem;
  transition: all .2s ease-in-out;
  display: flex;
}

.financing__header {
  font-family: var(--primary-font);
  color: #000;
  text-align: center;
  margin: 0;
  font-size: 1.125rem;
  transition: all .2s ease-in-out;
}

.financing__inside {
  background-color: var(--secondary-color);
  border: .375rem solid var(--form-color);
  box-sizing: content-box;
  border-top: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .625rem;
  height: 347px;
  padding: 0 .625rem;
  transition: all .2s ease-in-out;
  display: flex;
}

.financing__block-activated {
  background-color: var(--blue-color);
}

.financing__inside-activated {
  border-color: var(--blue-color);
}

.financing__header-activated {
  color: var(--secondary-color);
}

.financing__text {
  text-align: center;
  font-family: var(--primary-font);
  color: var(--primary-color);
  margin: 0;
  font-size: .875rem;
  line-height: 1;
}

.financing__icon, .financing__icon--image {
  width: 1.25rem;
  height: 1.25rem;
}

.financing__form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.financing__wrap {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: .625rem;
  display: flex;
}

.financing__icon--prev {
  top: 50%;
  left: -1.875rem;
}

.financing__icon--next {
  top: 50%;
  right: -1.875rem;
}

.financing__blur--left {
  background: linear-gradient(to left, transparent 0%, var(--secondary-color) 100%);
  z-index: 5;
  width: 1.875rem;
  height: 403px;
  position: absolute;
  top: 8.875rem;
  left: 0;
}

.financing__blur--right {
  background: linear-gradient(to right, transparent 0%, var(--secondary-color) 100%);
  z-index: 5;
  width: 1.875rem;
  height: 403px;
  position: absolute;
  top: 8.875rem;
  right: 0;
}

.financing__animation {
  opacity: 1;
  transition: opacity .5s ease-in;
}

@media screen and (width >= 980px) {
  .financing {
    padding: 0 6.25rem;
    overflow: visible;
  }

  .financing__wrapper {
    flex-direction: row;
  }

  .financing__container, .financing__wrap {
    width: 100%;
    height: 345px;
  }

  .financing__list {
    justify-content: space-between;
    align-items: center;
    gap: 1.875rem;
    width: 100%;
    height: 345px;
  }

  .financing__element {
    width: 100%;
    height: 100%;
  }

  .financing__block {
    width: 100%;
    height: 5rem;
  }

  .financing__header {
    font-size: 1.5rem;
  }

  .financing__inside {
    height: 253px;
  }

  .financing__text {
    font-size: .75rem;
  }

  .financing__icon, .financing__icon--image {
    width: .9375rem;
    height: .9375rem;
  }

  .none {
    display: none;
  }
}

@media screen and (width >= 1450px) {
  .financing__text {
    font-size: .875rem;
  }

  .financing__icon, .financing__icon--image {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.footer {
  background-color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 51.5rem;
  margin-top: 3.125rem;
  display: flex;
}

.footer__container {
  z-index: 0;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 467px;
  display: flex;
}

.footer__container:last-of-type {
  width: 100%;
  height: 357px;
}

.footer__block {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.footer__wrapper {
  flex-direction: column;
  height: 100%;
  margin-left: 0;
  display: flex;
}

.footer__header {
  font-family: var(--secondary-font);
  color: #fff;
  margin: 1.125rem 0 .8125rem;
  font-size: 1.5rem;
}

.footer__logo {
  font-family: var(--primary-font);
  color: var(--secondary-color);
  text-wrap: nowrap;
  margin-top: 65px;
  margin-left: 0;
  font-size: 60px;
  font-weight: 100;
}

.footer__text {
  color: var(--secondary-color);
  font-family: var(--primary-font);
  text-wrap: balance;
  margin: 0;
  font-size: 1rem;
  line-height: 1.6;
}

.footer__link {
  cursor: pointer;
  color: var(--blue-color);
}

.footer__text--block {
  justify-content: space-between;
  width: 12.5rem;
  display: flex;
}

@media screen and (width >= 980px) {
  .footer {
    flex-direction: row;
    height: 330px;
    margin-top: 7.375rem;
  }

  .footer__container {
    align-items: normal;
    width: 50%;
    height: 330px;
  }

  .footer__container:last-of-type {
    width: 50%;
    height: 330px;
  }

  .footer__logo {
    width: 17.5rem;
    height: 35px;
    margin-top: 2.75rem;
    margin-left: 6.25rem;
  }

  .footer__block {
    width: 50%;
  }

  .footer__block:first-child {
    margin-left: 6.25rem;
  }

  .footer__wrapper {
    flex-direction: row;
  }

  .footer__text--block {
    width: 8.75rem;
  }
}

@media screen and (width >= 1450px) {
  .footer__logo {
    width: 395px;
    height: 3.75rem;
  }

  .footer__text {
    font-size: 1.125rem;
  }

  .footer__text--block {
    width: 12.5rem;
  }
}

.intro__video {
  width: 100vw;
  height: 100vh;
  position: absolute;
  inset: 0;
}

.intro__video video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.csc__logo {
  width: 111px;
  height: 2.125rem;
  transition: all 1s ease-in-out;
  position: absolute;
  bottom: 1.25rem;
}

.csc__logo image {
  width: 111px;
  height: 2.125rem;
}

.balance {
  text-wrap: balance;
  max-width: 11.25rem;
  margin-bottom: 1.875rem;
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

.login__image {
  min-width: 20.375rem;
  min-height: 9.875rem;
  max-height: 14.875rem;
}

.login__image img {
  width: 100%;
  height: 100%;
}

.login__main {
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.login__container {
  background-color: var(--secondary-color);
  flex-direction: column;
  min-width: 20.375rem;
  max-width: 50rem;
  max-height: 100vh;
  margin: 0 12.25rem;
  display: flex;
  transform: translateY(12.5rem);
}

.login__container--transform {
  transition: transform 1s;
  transform: translateY(0);
}

.login__block {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.login__logo {
  height: 4.375rem;
  margin: 47px 0 2.25rem;
}

.login__header {
  font-family: var(--primary-font);
  text-transform: uppercase;
  margin: 0 0 .625rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.login__text--step {
  font-family: var(--primary-font);
  color: var(--primary-color);
  text-align: center;
  margin: 0 0 1.0625rem;
  font-size: .875rem;
}

.login__text {
  font-family: var(--primary-font);
  color: var(--primary-color);
  text-align: center;
  margin: 0;
  font-size: .875rem;
}

.login__text:first-of-type {
  margin-bottom: 23px;
}

.login__label {
  font-family: var(--primary-font);
  color: var(--primary-color);
  text-align: center;
  flex-direction: column;
  gap: .75rem;
  margin: 1.9375rem 0 0;
  font-size: .875rem;
  display: flex;
}

.login__label--error {
  color: #ff2121;
  font-size: .875rem;
  line-height: 2;
  font-family: var(--primary-font);
}

.login__input {
  text-align: center;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  outline: none;
  width: 16.25rem;
  height: 3.125rem;
  padding: 1.125rem 0;
  transition: all .1s ease-in-out;
}

.login__input::placeholder {
  letter-spacing: .1rem;
  color: #000;
  text-align: center;
  opacity: .5;
  font-size: 1.2rem;
}

.login__button--black {
  cursor: pointer;
  font-family: var(--primary-font);
  color: #fff;
  text-transform: uppercase;
  background-color: #000;
  border: 1px solid #000;
  width: 7.875rem;
  height: 3.75rem;
  margin-top: 1.5rem;
  margin-bottom: 168px;
  font-size: 1.125rem;
  transition: all .2s ease-in-out;
}

.login__button--black:hover {
  background-color: var(--secondary-color);
  color: #000;
  border-radius: 50px;
  transition: all .2s ease-in-out;
}

.login__button--bordo {
  cursor: pointer;
  font-family: var(--primary-font);
  color: #fff;
  text-transform: uppercase;
  background-color: #801c62;
  border: 1px solid #801c62;
  width: 7.875rem;
  height: 3.75rem;
  margin-top: 1.5rem;
  margin-bottom: 168px;
  font-size: 1.125rem;
  transition: all .2s ease-in-out;
}

.login__button--bordo:hover {
  color: #801c62;
  background-color: #fff;
  border-radius: 50px;
  transition: all .2s ease-in-out;
}

.login__second--container {
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.login__second--container-transform {
  opacity: 1;
  transition: opacity 1s;
}

.login__text--confirm {
  font-family: var(--primary-font);
  margin: 0 0 .625rem;
  font-size: .75rem;
  font-weight: 600;
}

.login__description {
  font-family: var(--primary-font);
  color: var(--primary-color);
  text-align: center;
  text-wrap: balance;
  width: 15.625rem;
  margin: 0 0 31px;
  font-size: .75rem;
}

.login__helper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 1s ease-in-out;
  display: flex;
  overflow: hidden;
}

.login__helper-transform {
  margin-bottom: 100px;
  transition: all 1s ease-in-out;
}

.login__send--again {
  font-family: var(--primary-font);
  color: var(--blue-color);
  cursor: pointer;
  text-transform: uppercase;
  margin: 0 0 31px;
  font-size: .75rem;
  font-weight: 600;
  text-decoration: underline;
  transform: translateY(-30px);
}

.login__label--confirm {
  font-family: var(--primary-font);
  color: var(--primary-color);
  text-align: center;
  flex-direction: column;
  gap: 17px;
  margin: 0;
  font-size: .875rem;
  display: flex;
  transform: translateY(-45px);
}

.login__input--container {
  gap: .3125rem;
  height: 3.125rem;
  margin-bottom: 1.375rem;
  display: flex;
}

.login__input--digit {
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  text-align: center;
  font-family: var(--primary-font);
  outline: none;
  align-items: center;
  width: 2.75rem;
  height: 2.75rem;
  padding: 0;
  font-size: 1.625rem;
  display: flex;
}

.login__button--confirm {
  color: var(--secondary-color);
  cursor: pointer;
  font-family: var(--primary-font);
  text-transform: uppercase;
  background-color: #000;
  border: 1px solid #000;
  width: 11.125rem;
  height: 3.75rem;
  margin-bottom: 123px;
  font-size: 1.125rem;
  transition: all .2s ease-in-out;
  transform: translateY(-45px);
}

.login__button--confirm:hover {
  background-color: var(--secondary-color);
  color: #000;
  transition: all .2s ease-in-out;
}

.login__sendAgain-transform {
  transition: transform .5s;
  transform: translateY(0);
}

@media screen and (width >= 1100px) {
  .login__container {
    flex-direction: row;
    min-width: 1100px;
    max-width: 1418px;
    height: 464px;
    margin: 0;
  }

  .login__image {
    max-width: 1013px;
    height: 100%;
    max-height: 100%;
  }

  .login__image img {
    width: 720px;
    height: 464px;
  }

  .login__block {
    min-width: 405px;
    height: 100%;
  }

  .login__input--digit {
    width: 3.125rem;
    height: 3.125rem;
  }

  .login__button--black, .login__button--bordo, .login__button--confirm {
    margin-bottom: 0;
  }

  .login__text:first-of-type {
    margin-bottom: 1.25rem;
  }

  .login__logo {
    margin: 20px 0 .25rem;
    scale: .8;
  }

  .login__label {
    margin: 1.5rem 0 0;
  }

  .login__input {
    width: 12rem;
    height: 2.5rem;
    font-size: 18px;
  }
}

@media screen and (width >= 1410px) {
  .login__container {
    height: 651px;
  }

  .login__image img {
    width: 100%;
    height: 100%;
  }

  .login__logo {
    margin: 47px 0 2.25rem;
    scale: 1;
  }

  .login__text:first-of-type {
    margin-bottom: 3.75rem;
  }

  .login__text {
    font-size: 1rem;
  }

  .login__label {
    margin: 1.9375rem 0 0;
    font-size: 1rem;
  }

  .login__input {
    width: 16.25rem;
    height: 3.125rem;
    padding: 1.75rem 0;
    font-size: 24px;
  }

  .login__text--confirm {
    font-size: 1rem;
  }

  .login__description, .login__send--again, .login__label--confirm {
    font-size: .9rem;
  }

  .balance {
    max-width: 13rem;
  }
}

.main {
  padding-left: 2.5rem;
  position: relative;
}

.main__preview {
  color: #fff;
  font-family: var(--primary-font);
  background-color: #18ac2c;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  font-size: 22px;
  display: flex;
}

.main__text {
  font-size: 30px;
  font-family: var(--primary-font);
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.loader__container {
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.adnotation__text {
  text-align: center;
  text-wrap: balance;
  font-family: var(--primary-font);
  color: var(--primary-color);
  width: 100%;
}

@media screen and (width >= 980px) {
  .main {
    padding-left: 13.125rem;
  }
}

@media screen and (width >= 1450px) {
  .main {
    padding-left: 16.375rem;
  }
}

@media print {
  .sidebar__wrapper, .footer, .yourfiles {
    display: none !important;
  }

  .slider__car--info {
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    height: 231px !important;
    margin: 1.25rem 0 0 !important;
    padding: 0 6.25rem !important;
  }

  .slider__car--text {
    font-size: 1.5rem !important;
  }

  .slider__car--block, .slider__car--block--bordo, .slider__car--block--blue {
    gap: 0 !important;
    width: 18.125rem !important;
    max-width: 18.125rem !important;
    height: 11.25rem !important;
    max-height: 11.25rem !important;
    padding-left: 1.5rem !important;
  }

  .slider__car--font {
    margin: 0 !important;
    font-size: 1rem !important;
  }

  .slider__car--description {
    margin: 39px 0 .375rem !important;
    font-size: 1.25rem !important;
  }

  .slider__car--price {
    width: 18.75rem !important;
    margin: 0 !important;
    font-size: 3.75rem !important;
  }

  .slider__wrapper, .slider__wrapper--gallery {
    height: 37.5rem !important;
  }

  .slider__main--image {
    width: 66.75rem !important;
    height: 37.5rem !important;
  }

  .heading {
    font-size: 3rem !important;
  }

  .configuration__list {
    flex-wrap: nowrap !important;
    row-gap: 0 !important;
    margin: 1.25rem 0 4.375rem !important;
    padding: 0 5rem !important;
  }

  .element__info {
    margin: 2.25rem 0 .25rem !important;
    font-size: .875rem !important;
  }

  .element__detail {
    text-wrap: balance !important;
    text-align: center !important;
    font-size: 1.5rem !important;
  }

  .configuration__table {
    width: 60.125rem !important;
    margin-bottom: 4.375rem !important;
  }

  .table__data:first-child {
    width: 6.25rem !important;
  }

  .table__data:last-child {
    width: 9.375rem !important;
  }

  .sliderCarBlock__container, .specials__list {
    flex-direction: row !important;
  }
}

.sidebar {
  z-index: 100;
  background-color: #000;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 3.875rem;
  height: 100vh;
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.sidebar__multiselect {
  color: #fff;
  font-family: var(--primary-font);
  background-color: #0000;
  border: 1px solid #fff;
  width: 90%;
  height: 2.75rem;
  margin-bottom: 1.25rem;
}

.sidebar .multiselect-single-label {
  width: 100%;
}

.sidebar .multiselect-option {
  color: #fff;
  cursor: pointer;
  background-color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: min-content;
  display: flex;
}

.sidebar .multiselect-option p {
  margin: 0;
}

.sidebar .multiselect-option p:last-child {
  opacity: .5;
}

.sidebar .multiselect-option:hover {
  background-color: var(--blue-color);
}

.sidebar__wrapper {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.sidebar__block-date {
  background-color: var(--blue-color);
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.sidebar__block-date--bordo {
  background-color: #801c62;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.sidebar__inner {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.sidebar__text {
  color: var(--secondary-color);
  font-family: var(--primary-font);
  letter-spacing: 1.4px;
  margin: 2.125rem 0 .45rem;
  font-size: .875rem;
  line-height: 1.2;
  display: block;
}

.sidebar__date {
  color: var(--secondary-color);
  text-align: center;
  font-family: var(--primary-font);
  letter-spacing: 1.2px;
  margin: 0 0 2.125rem;
  font-size: 1.5rem;
  line-height: 1.2;
  display: block;
}

.sidebar__compare {
  background-color: var(--blue-color);
  opacity: .5;
  color: var(--secondary-color);
  font-family: var(--primary-font);
  cursor: auto;
  border: none;
  justify-content: center;
  align-items: center;
  width: 10rem;
  min-height: 35px;
  margin: 1.875rem 0;
  font-size: .875rem;
  display: flex;
}

.sidebar__compare--bordo {
  opacity: .5;
  color: var(--secondary-color);
  font-family: var(--primary-font);
  cursor: auto;
  background-color: #801c62;
  border: none;
  justify-content: center;
  align-items: center;
  width: 10rem;
  min-height: 35px;
  margin: 1.875rem 0;
  font-size: .875rem;
  display: flex;
}

.sidebar__files {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  font-size: .875rem;
  font-family: var(--primary-font);
  cursor: pointer;
  border: none;
  margin-bottom: 50px;
  padding: 10px 50px;
  transition: all .1s ease-in-out;
}

.sidebar__files:hover {
  background-color: var(--blue-color);
  transition: all .1s ease-in-out;
}

.sidebar__compare--allowed {
  opacity: 1;
  cursor: pointer;
}

.sidebar__mobile--main {
  z-index: 99;
  background-color: #000;
  width: 2.5rem;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.sidebar__helper {
  position: relative;
  top: 40%;
}

.sidebar__mobile--element, .sidebar__mobile--element-bordo {
  border-bottom: 1px solid var(--primary-color);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3.75rem;
  display: flex;
}

.sidebar__mobile--element:hover .opacity {
  opacity: 1;
}

.sidebar__mobile--element:first-child, .sidebar__mobile--element-bordo:first-child {
  border-top: 1px solid var(--primary-color);
}

.sidebar__mobile--element:hover {
  background-color: var(--blue-color);
  cursor: pointer;
}

.sidebar__mobile--element-bordo:hover {
  cursor: pointer;
  background-color: #801c62;
}

.sidebar__mobile {
  z-index: 100;
  background-color: #000;
  flex-direction: column;
  align-items: center;
  width: 19.375rem;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: -19.375rem;
  overflow-x: hidden;
  overflow-y: scroll;
}

.sidebar__mobile .multiselect-single-label {
  width: 100%;
}

.sidebar__mobile .multiselect-option {
  color: #fff;
  cursor: pointer;
  background-color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: min-content;
  display: flex;
}

.sidebar__mobile .multiselect-option p {
  margin: 0;
}

.sidebar__mobile .multiselect-option p:last-child {
  opacity: .5;
}

.sidebar__mobile .multiselect-option:hover {
  background-color: var(--blue-color);
}

.sidebar__transform--left {
  transition: transform .5s;
  transform: translateX(-19.375rem);
}

.sidebar__transform--right {
  transition: transform .5s;
  transform: translateX(19.375rem);
}

.sidebar__block {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

.sidebar__consultant {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sidebar__picture {
  background-color: var(--secondary-color);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 3.75rem;
  height: 3.75rem;
  display: flex;
  overflow: hidden;
}

.sidebar__picture img {
  border-radius: 50%;
  width: 100px;
  height: 67px;
}

.sidebar__picture--header {
  color: var(--secondary-color);
  font-family: var(--primary-font);
  text-align: center;
  margin: 0;
  font-size: 1.125rem;
}

.sidebar__picture--description {
  color: var(--primary-color);
  font-family: var(--primary-font);
  text-align: center;
  width: 10.125rem;
  margin: 0 0 .45rem;
  font-size: .875rem;
  line-height: 1.6;
}

.sidebar__button {
  background-color: var(--blue-color);
  color: var(--secondary-color);
  font-family: var(--primary-font);
  letter-spacing: 1.2px;
  border: none;
  justify-content: center;
  align-items: center;
  width: 6.875rem;
  height: 35px;
  font-size: .875rem;
  text-decoration: none;
  display: flex;
}

.sidebar__button:hover {
  cursor: pointer;
  background-color: #000;
  width: auto;
  font-size: 1.125rem;
}

.sidebar__button--bordo {
  color: var(--secondary-color);
  font-family: var(--primary-font);
  letter-spacing: 1.2px;
  background-color: #801c62;
  border: none;
  justify-content: center;
  align-items: center;
  width: 6.875rem;
  height: 35px;
  font-size: .875rem;
  text-decoration: none;
  display: flex;
}

.sidebar__button--bordo:hover {
  cursor: pointer;
  background-color: #000;
  width: auto;
  font-size: 1.125rem;
}

.sidebar__header {
  color: var(--secondary-color);
  font-family: var(--secondary-font);
  letter-spacing: .8px;
  margin: 1.675rem 0 1.25rem;
  font-size: 1.125rem;
  display: block;
}

.sidebar__block {
  width: 100%;
  display: flex;
}

.sidebar__prev {
  top: 50%;
  left: -1.25rem;
}

.sidebar__next {
  top: 50%;
  right: -1.25rem;
}

.opacity {
  opacity: .25;
}

.blur {
  z-index: 90;
  -webkit-backdrop-filter: blur(.25rem);
  backdrop-filter: blur(.25rem);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.side__container {
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 50px;
  display: flex;
}

.side__text {
  color: var(--secondary-color);
  font-family: var(--secondary-font);
  letter-spacing: .8px;
  text-align: center;
  margin: 2.375rem 0 0;
  font-size: 1.125rem;
}

.side__container--mobile {
  align-items: center;
  gap: .625rem;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  display: flex;
  position: relative;
}

.side__block--mobile {
  gap: 3.125rem;
  width: 10.125rem;
  display: flex;
}

.side__detail--container {
  width: 100%;
  margin-bottom: 100px;
}

.side__detail--mobile {
  border-bottom: 1px solid var(--primary-color);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3.75rem;
  display: flex;
}

.side__detail--mobile:first-child {
  border-top: 1px solid var(--primary-color);
}

.side__detail--text {
  font-family: var(--primary-font);
  color: var(--secondary-color);
  margin-left: 1.25rem;
  font-size: .875rem;
}

.side__detail--icon {
  opacity: .25;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1.125rem;
}

.side__detail--image {
  width: 1.5rem;
  height: 1.5rem;
}

.side__detail--mobile:hover .side__detail--icon {
  opacity: 1;
}

.side__detail--mobile:hover {
  background-color: var(--blue-color);
  cursor: pointer;
}

.side__offer--link {
  border-bottom: 1px solid var(--primary-color);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3.75rem;
  padding: 0 .625rem;
  display: flex;
}

.side__offer--link:first-child {
  border-top: 1px solid var(--primary-color);
  margin-top: 15.625rem;
}

.side__offer--link:last-child {
  margin-bottom: 3.125rem;
}

.side__offer--link:hover {
  background-color: var(--blue-color);
  cursor: pointer;
}

.side__offer--link:hover .side__offer--icon {
  opacity: 1;
}

.side__offer--text {
  font-family: var(--primary-font);
  color: var(--secondary-color);
  margin: 0 0 0 1.25rem;
  font-size: .875rem;
}

.side__offer--icon {
  opacity: .25;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 1.25rem 0 0;
}

.side__offer--image {
  width: 1.5rem;
  height: 1.5rem;
}

.offer__button, .offer__button--bordo {
  color: var(--secondary-color);
  border: none;
  border-bottom: 1px solid var(--primary-color);
  background-color: #000;
  justify-content: space-between;
  align-items: center;
  gap: .75rem;
  width: 100%;
  height: 3.75rem;
  padding: 0 .875rem;
  display: flex;
}

.offer__button:first-child, .offer__button--bordo:first-child {
  border-top: 1px solid var(--primary-color);
}

.offer__button:hover {
  background-color: var(--blue-color);
  cursor: pointer;
}

.offer__button--bordo:hover {
  cursor: pointer;
  background-color: #801c62;
}

.offer__icon, .offer__icon--image {
  width: .875rem;
  height: 1.25rem;
}

.offer__button--description {
  font-family: var(--primary-font);
  letter-spacing: .7px;
  width: 100%;
  margin: .1875rem;
  font-size: .875rem;
}

.offer__button--check {
  border: 1px solid #fff;
  border-radius: .1875rem;
  width: 21px;
  height: 1rem;
  display: block;
  position: relative;
}

.offer__check--icon {
  position: absolute;
  top: 1px;
  left: -1px;
}

.offer__block {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  display: flex;
}

.offer__container--block {
  width: 100%;
}

.files__button {
  color: var(--secondary-color);
  border-bottom: 1px solid var(--primary-color);
  border-top: 1px solid var(--primary-color);
  background-color: #000;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 2rem);
  height: 4.5rem;
  padding: 0 1rem;
  display: flex;
}

.files__button p {
  font-family: var(--primary-font);
  font-size: .875rem;
}

.files__button img {
  width: 20px;
  height: 20px;
}

.files__button:hover {
  background-color: var(--blue-color);
  cursor: pointer;
}

.files__button:last-of-type {
  border-top: none;
  margin-bottom: 50px;
}

.is-hidden-icon {
  display: none;
}

.button__is-clicked {
  background-color: var(--blue-color);
}

.button__is-clicked--bordo {
  background-color: #801c62;
}

.box__is-clicked {
  background-color: var(--secondary-color);
}

.blur-isOpen {
  width: 100%;
}

@media screen and (width >= 980px) {
  .sidebar {
    width: 13.125rem;
  }

  .sidebar__compare {
    margin: 3.125rem 0 1rem;
  }

  .sidebar__block {
    flex-direction: row;
  }

  .sidebar__consultant {
    align-items: flex-start;
  }

  .sidebar__picture--header, .sidebar__picture--description {
    text-align: left;
  }

  .side__container {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 50px;
    display: flex;
  }
}

@media screen and (width >= 1450px) {
  .sidebar {
    width: 16.375rem;
  }

  .offer__button--check {
    width: 1.25rem;
  }
}

.slider__zip {
  --slider-bg: black;
  --slider-connect-bg: var(--blue-color);
  --slider-tooltip-bg: var(--blue-color);
  width: 96%;
  margin-bottom: 10px;
}

.slider__placeholder--image {
  max-width: 70%;
}

.slider__horizontal {
  justify-content: center;
  width: 100%;
  display: flex;
}

.slider__car--info {
  opacity: 0;
  flex-direction: column;
  margin-top: 1.875rem;
  padding: 0 2.125rem;
  display: flex;
}

.slider__car--name {
  opacity: 1;
  max-width: 13.125rem;
}

.slider__car--client-name {
  font-family: var(--primary-font);
  font-weight: 600;
}

.slider__car--text {
  font-size: 1.875rem;
  font-family: var(--secondary-font);
  margin: 0;
}

.slider__car--text:first-of-type {
  text-wrap: nowrap;
}

.slider__car--text-medium {
  font-size: 1.75rem;
  font-family: var(--secondary-font);
  margin: 0;
}

.slider__car--text-small {
  font-size: 1.5rem;
  font-family: var(--secondary-font);
  margin: 0;
}

.slider__button--container {
  opacity: 0;
  justify-content: center;
  align-items: center;
  height: 6.875rem;
  display: flex;
}

.slider__button {
  font-family: var(--primary-font);
  cursor: pointer;
  color: var(--primary-color);
  border: none;
  height: 3.125rem;
  padding: .9375rem 1.25rem;
  font-size: .875rem;
  box-shadow: inset 0 .3125rem .625rem #00000029;
}

.slider__car--block {
  background-color: var(--primary-color);
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  width: 17.125rem;
  max-width: 19.875rem;
  height: 11.25rem;
  max-height: 113px;
  margin-top: 1.875rem;
  padding-left: 1.625rem;
  display: flex;
}

.slider__car--block--blue {
  background-color: var(--blue-color);
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  width: 17.125rem;
  max-width: 19.875rem;
  height: 11.25rem;
  max-height: 113px;
  margin-top: 1.875rem;
  padding-left: 1.625rem;
  display: flex;
}

.slider__car--block--bordo {
  background-color: #801c62;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  width: 17.125rem;
  max-width: 19.875rem;
  height: 11.25rem;
  max-height: 113px;
  margin-top: 1.875rem;
  padding-left: 1.625rem;
  display: flex;
}

.slider__car--description {
  align-items: baseline;
  line-height: 1;
  display: flex;
}

.slider__car--description .slider__car--font {
  margin: .35rem 0 0 !important;
}

.slider__car--font {
  font-family: var(--primary-font);
  color: var(--secondary-color);
  width: 15.625rem;
  margin: 0;
  font-size: .875rem;
}

.slider__car--font:last-child {
  margin: 1.2rem 0 0;
}

.slider__car--price {
  font-size: 1.875rem;
  font-family: var(--secondary-font);
  color: var(--secondary-color);
  width: 12rem;
  margin: 0;
  line-height: .9;
}

.slider__thumbnail {
  width: 1108px;
  overflow: hidden;
}

.slider__thumbnail--relative {
  display: none;
  position: relative;
}

.slider__thumbnail--relative-next {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 50px;
  right: -25px;
}

.slider__thumbnail--relative-next image {
  width: 100%;
}

.slider__thumbnail--relative-prev {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 50px;
  left: -25px;
}

.slider__thumbnail--relative-prev image {
  width: 100%;
}

.slider__thumbnail--list {
  gap: 20px;
  height: 68px;
  margin: 30px 0;
  padding: 0;
  list-style: none;
  transition: all .5s ease-in-out;
  display: flex;
  position: relative;
}

.slider__thumbnail--element {
  box-sizing: border-box;
  background-color: #f1f1f1;
  justify-content: center;
  align-items: center;
  min-width: 121px;
  min-height: 68px;
  display: flex;
  box-shadow: 0 .3125rem 1.25rem #00000065;
}

.slider__thumbnail--element img {
  width: auto;
  max-width: 100%;
  height: 100%;
}

.slider__main--image {
  background-color: #f0f1f0;
  justify-content: center;
  align-items: center;
  width: 19.75rem;
  height: 11.125rem;
  display: flex;
}

.slider__main--image img {
  max-width: 100%;
  max-height: 100%;
}

.slider__main {
  opacity: 0;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  display: flex;
  overflow: hidden;
}

.slider__main--loader {
  transform: translateY(-100px);
}

.slider__main .carousel {
  width: 100%;
}

.slider__wrapper {
  justify-content: center;
  align-items: center;
  height: 11.125rem;
  margin-left: 4.125rem;
  display: flex;
  position: relative;
}

.slider__wrapper--gallery {
  justify-content: center;
  align-items: center;
  height: 11.125rem;
  display: flex;
  position: relative;
}

.slider__list--container {
  width: 23.875rem;
  height: 11.125rem;
}

.slider__list {
  gap: 4.125rem;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.slider__element {
  max-width: 23.875rem;
  max-height: 11.125rem;
}

.slider__element video, .slider__element source {
  width: 19.75rem;
  height: 11.125rem;
}

.slider__icon {
  cursor: pointer;
  z-index: 5;
  width: .625rem;
  height: 1.25rem;
  position: absolute;
}

.slider__icon image {
  width: .625rem;
  height: 1.25rem;
}

.slider__description {
  font-family: var(--primary-font);
  color: #000;
  text-align: center;
  text-wrap: balance;
  width: 100%;
  margin: 1.375rem 0 .375rem;
  padding: 0 6.25rem;
  font-size: 1rem;
}

.slider__info {
  font-family: var(--primary-font);
  color: var(--primary-color);
  margin: 0;
  font-size: .625rem;
}

.slider__prev--icon {
  top: 50%;
  left: -1.25rem;
}

.slider__next--icon {
  top: 50%;
  right: 2.875rem;
}

.slider__slide-content {
  width: 100%;
  min-height: 100%;
  padding-right: 4.125rem;
}

.slider__animation {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.active {
  background-color: #000;
}

.active__thumbnail {
  border: 3px solid var(--blue-color);
  color: var(--secondary-color);
  background-color: #f1f1f1;
  min-width: 121px;
  min-height: 68px;
}

.active__thumbnail--image {
  width: auto;
  height: 100%;
}

.active__thumbnail--bordo {
  background-color: #f1f1f1;
  border: 3px solid #801c62;
  min-width: 121px;
  min-height: 68px;
}

.active__thumbnail--bordo--image {
  width: auto;
  height: 100%;
}

.carousel__viewport {
  height: 100%;
  overflow: visible;
}

.carousel__track {
  height: 100%;
}

.gallery__next--icon {
  position: absolute;
  top: 50%;
  right: -.9rem;
}

.gallery__prev--icon {
  position: absolute;
  top: 50%;
  left: -.9rem;
}

.sliderCarBlock__container {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  display: flex;
}

@media screen and (width >= 550px) {
  .slider__car--info {
    justify-content: space-between;
    align-items: center;
    margin: 1.25rem 0 0;
    padding: 0 6.25rem;
  }

  .slider__car--name {
    max-width: 17.5rem;
  }

  .slider__car--text {
    font-size: 2.5rem;
  }

  .slider__car--text-medium {
    font-size: 2.2rem;
  }

  .slider__car--text-small {
    font-size: 1.75rem;
  }

  .slider__main--image {
    width: 26.25rem;
    height: 15rem;
  }

  .slider__wrapper, .slider__wrapper--gallery {
    height: 15rem;
  }

  .slider__list--container {
    width: 30.375rem;
    height: 15rem;
  }

  .slider__element {
    max-width: 30.375rem;
    max-height: 15rem;
  }

  .slider__element video, .slider__element source {
    width: 26.25rem;
    height: 15rem;
  }

  .slider__description {
    margin: 39px 0 .375rem;
    font-size: 1rem;
  }

  .slider__info {
    font-size: .875rem;
  }
}

@media screen and (width >= 980px) {
  .sliderCarBlock__container {
    flex-direction: row;
  }

  .slider__thumbnail--relative, .slider__thumbnail--relative-prev, .slider__thumbnail--relative-next {
    display: block;
  }

  .slider__car--info {
    flex-direction: row;
  }

  .slider__button--container {
    height: 137px;
  }

  .slider__car--block {
    gap: 0;
    width: 18.125rem;
    max-width: 18.125rem;
    height: 11.25rem;
    max-height: 11.25rem;
    padding-left: 1.5rem;
  }

  .slider__car--font {
    font-size: 1rem;
  }

  .slider__car--price {
    width: 15.625rem;
    font-size: 3rem;
  }

  .slider__car--description {
    flex-direction: column;
  }

  .slider__main--image {
    width: 38.75rem;
    height: 20rem;
  }

  .slider__wrapper, .slider__wrapper--gallery {
    height: 20rem;
  }

  .slider__list--container {
    width: 42.875rem;
    height: 20rem;
  }

  .slider__element {
    max-width: 42.875rem;
    max-height: 20rem;
  }

  .slider__element video {
    width: 38.75rem;
    height: 20rem;
  }

  .slider__icon, .slider__icon image {
    width: 23px;
    height: 2.5rem;
  }

  .slider__description {
    margin: 39px 0 .375rem;
    font-size: 1.25rem;
  }

  .slider__prev--icon {
    left: -2.5rem;
  }

  .slider__next--icon {
    right: 1.625rem;
  }

  .gallery__next--icon {
    position: absolute;
    top: 50%;
    right: -2.5rem;
  }

  .gallery__prev--icon {
    position: absolute;
    top: 50%;
    left: -2.5rem;
  }
}

@media screen and (width >= 1450px) {
  .slider__car--name {
    max-width: 407px;
  }

  .slider__car--text {
    font-size: 3.75rem;
  }

  .slider__car--text-medium {
    font-size: 2.75rem;
  }

  .slider__car--text-small {
    font-size: 2rem;
  }

  .slider__car--block, .slider__car--block--blue, .slider__car--block--bordo {
    width: 21.375rem;
    max-width: 21.375rem;
    height: 12.125rem;
    max-height: 12.125rem;
    padding-left: 2.375rem;
  }

  .slider__car--price {
    width: 18.75rem;
    font-size: 3.75rem;
  }

  .slider__main--image {
    width: 66.75rem;
    height: 37.5rem;
  }

  .slider__wrapper, .slider__wrapper--gallery {
    height: 37.5rem;
  }

  .slider__list--container {
    width: 70.875rem;
    height: 37.5rem;
  }

  .slider__element {
    max-width: 70.875rem;
    max-height: 37.5rem;
  }

  .slider__element video {
    width: 66.75rem;
    height: 37.5rem;
  }
}

@media screen and (width >= 1650px) {
  .slider .carousel__slide {
    padding: 0 294px;
  }
}

.specials {
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 37px;
  display: flex;
}

.specials__list {
  flex-direction: column;
  justify-content: space-between;
  gap: 1.875rem;
  width: 100%;
  padding: 0;
  list-style: none;
  display: flex;
}

.specials__element {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  width: 100%;
  height: 100%;
  display: flex;
}

.specials__element img {
  width: 100%;
  height: 100%;
}

.specials__description {
  font-family: var(--primary-font);
  text-align: center;
  text-wrap: balance;
  margin: 0;
  font-size: 1.125rem;
}

.specials__description br {
  display: none;
}

.specials__animation {
  opacity: 1;
  transition: opacity .5s ease-in;
}

@media screen and (width >= 980px) {
  .specials {
    padding: 0 6.25rem;
  }

  .specials__list {
    flex-direction: row;
  }

  .specials__element {
    gap: 1.875rem;
  }

  .specials__description {
    height: 3.75rem;
  }
}

@media screen and (width >= 1450px) {
  .specials__description {
    font-size: 1.5rem;
  }
}

.yourfiles {
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.yourfiles__container--mobile {
  width: 100%;
  margin-top: 2rem;
}

.yourfiles__block--mobile {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 135px;
  display: flex;
  position: relative;
}

.yourfiles__download--icon {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 1.25rem;
  right: 2.25rem;
}

.yourfiles__block--mobile:nth-child(odd) {
  background-color: #f0f1f0;
}

.yourfiles__block--header {
  font-family: var(--primary-font);
  color: #000;
  max-width: 15.625rem;
  margin: 0 37px;
  font-size: 1.125rem;
}

.yourfiles__download--image {
  width: 1.5rem;
  height: 1.5rem;
}

.yourfiles__block--date {
  font-family: var(--primary-font);
  color: var(--primary-color);
  margin: .3125rem 37px .75rem;
  font-size: 1.125rem;
}

.yourfiles__block--text {
  font-family: var(--primary-font);
  color: var(--primary-color);
  text-wrap: balance;
  margin: 0 37px;
  font-size: .875rem;
}

.yourfiles__text {
  font-family: var(--primary-font);
  color: var(--primary-color);
  text-align: center;
  margin: 1.875rem 0 .875rem;
  font-size: .75rem;
}

.yourfiles__animation {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.yourfiles .heading__icon {
  display: none;
}

.underline {
  cursor: pointer;
  margin: 1.1875rem 0 1.25rem;
  text-decoration: underline;
}

.no-display {
  display: none;
}

@media screen and (width >= 610px) {
  .yourfiles__download--icon {
    top: 40%;
  }
}

@media screen and (width >= 980px) {
  .yourfiles {
    padding: 0 6.25rem;
  }

  .yourfiles__table {
    width: 100%;
    margin: 2.25rem 0 2.5rem;
  }

  .yourfiles__row {
    height: 7rem;
    padding: 0 4.375rem;
  }

  .yourfiles__row:first-child {
    height: 5rem;
  }

  .yourfiles__row:nth-child(2n) {
    background-color: #f0f1f0;
  }

  .yourfiles__data:first-child {
    max-width: 31rem;
  }

  .yourfiles__data:last-child {
    padding-right: 4.375rem;
  }

  .yourfiles__data {
    text-wrap: wrap;
    font-family: var(--primary-font);
    padding-left: 4.375rem;
    font-size: 1.125rem;
  }

  .yourfiles__data:nth-child(3) {
    color: var(--primary-color);
    text-wrap: balance;
    max-width: 20rem;
    font-size: .875rem;
  }

  .yourfiles__header {
    text-align: left;
    font-family: var(--primary-font);
    padding-left: 4.375rem;
    font-size: 1.125rem;
    font-weight: 500;
  }

  .yourfiles__header:first-child {
    padding-left: 4.375rem;
  }

  .yourfiles__icon {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
  }

  .yourfiles__icon--image {
    width: 1.5rem;
    height: 1.5rem;
  }

  .yourfiles__text {
    margin: 0 0 .875rem;
    font-size: .875rem;
  }

  .underline {
    margin: 1.1875rem 0 0;
  }
}

@media screen and (width >= 1450px) {
  .yourfiles__row {
    padding: 0 7.75rem;
  }

  .yourfiles__data:first-child {
    padding-left: 7.75rem;
  }

  .yourfiles__data:last-child {
    padding-right: 7.75rem;
  }

  .yourfiles__header:first-child {
    padding-left: 7.75rem;
  }
}

body {
  max-width: auto;
  min-width: 400px;
  margin: 0 auto;
  padding: 0;
  font-size: 16px;
  position: relative;
  overflow-x: hidden;
}

.multiselect {
  font-family: var(--primary-font);
  font-size: .875rem;
}

.multiselect-caret {
  background-color: var(--blue-color);
  width: .875rem;
  height: .875rem;
  -webkit-mask-image: url("arrow_down.a9c0f3e8.png");
  mask-image: url("arrow_down.a9c0f3e8.png");
}

.multiselect-dropdown {
  border: 1px solid var(--primary-color);
  border-top: 0;
  border-radius: 0;
  max-height: 18.75rem;
}

.multiselect-clear {
  display: none;
}

.multiselect-option.is-selected, .multiselect-option.is-selected.is-pointed, .multiselect-option.is-selected:hover {
  background: var(--blue-color);
}

.multiselect.is-active {
  box-shadow: none;
  border: 1px solid var(--primary-color);
  border-radius: 0;
}

.multiselect-option {
  border-top: 1px solid var(--form-color);
  height: 3.125rem;
  font-size: .875rem;
}

.chat {
  background-color: #801c62;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
  transition: all .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 40px;
}

.chat__navbar {
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  display: flex;
}

.chat__navbar--block {
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.chat__navbar--block img {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.chat__navbar--block-dealer {
  font-family: var(--primary-font);
  color: var(--secondary-color);
  font-size: 18px;
  font-weight: 600;
}

.chat__main {
  background-color: var(--secondary-color);
  border-radius: 10px;
  flex-direction: column;
  width: calc(100% - 20px);
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
}

.chat__main--messages {
  flex-direction: column;
  gap: 5px;
  height: 90%;
  max-height: 90%;
  display: flex;
  overflow-y: scroll;
}

.chat__main--messages::-webkit-scrollbar {
  display: none;
}

.chat__main--messages-user {
  flex-direction: column;
  align-items: end;
  gap: 5px;
  display: flex;
}

.chat__main--messages-user div:first-of-type {
  text-align: right;
  font-size: 9px;
  font-family: var(--primary-font);
  letter-spacing: .4px;
}

.chat__main--messages-user div:last-of-type {
  font-size: 13px;
  font-family: var(--primary-font);
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  background-color: #801c6233;
  border-radius: 10px;
  max-width: 80%;
  padding: 20px;
}

.chat__main--input {
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  height: calc(10% - 20px);
  margin-top: 10px;
  padding: 10px;
  position: relative;
}

.chat__main--input input {
  border: none;
  outline: none;
  width: 90%;
  height: 100%;
  padding-left: 20px;
}

.chat__main--input img {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.chat-hidden {
  cursor: pointer;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 20px;
  transition: all .3s ease-in-out;
}

.chat-shown-small {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  width: calc(30vw - 40px);
  height: 60vh;
  transition: all .3s ease-in-out;
}

.chat-shown-small .chat__main {
  height: 82%;
}

.chat-shown-big {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  width: 40vw;
  height: 90vh;
  transition: all .3s ease-in-out;
}

.chat-shown-big .chat__main {
  height: 88%;
}

/*# sourceMappingURL=index.49df0128.css.map */
